import React from 'react';
import PropTypes from 'prop-types';

function DisplayCampaignStoryEvents({ campaignData }) {
    const cleanDescription = (description) => {
        return description.replace(/<b>The current situation<\/b><br\/><br\/>/, '');
    };

    return (
        <div className="campaign-story-events-container">
              <h4 className="text-uppercase fw-bolder text-center pt-3">
        MissioN Brief
      </h4>
            <div className='border p-2'>
            <div className="fw-bold border-bottom">Current Situation</div>
            <div
                className="current-day-state-description"
                dangerouslySetInnerHTML={{ __html: cleanDescription(campaignData.CurrentDayStateDescription) }}
            />

            <div className="fw-bold mt-4 border-bottom">Yesterday's Events</div>
            <div
                className="previous-day-events-description"
                dangerouslySetInnerHTML={{ __html: campaignData.PreviousDaysEventsDescription }}
            />
        </div>
        </div>
    );
}

DisplayCampaignStoryEvents.propTypes = {
    campaignData: PropTypes.shape({
        CurrentDayStateDescription: PropTypes.string.isRequired,
        PreviousDaysEventsDescription: PropTypes.string.isRequired,
    }).isRequired,
};

export default DisplayCampaignStoryEvents;
