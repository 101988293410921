import React from "react";
import '../styles/Main/main.css';

function Main() {
    return (
        <div className="main">


            <h2 className="text-uppercase fw-bolder text-center pt-3">
                CURRATED MISSIONS
            </h2>

            <div className="container">
                <div className="col-12 text-center pt-5">
                This page will have currated mission information in the future.
                </div>

            </div>
        </div>
    );
}

export default Main;
