import React from "react";
import PropTypes from "prop-types";

function DisplayWeatherForecast({ weather }) {
    // Utility to parse wind bearing
    const parseBearing = (windDescription) => {
        const match = windDescription.match(/(\d{1,3})°/);
        return match ? parseInt(match[1], 10) : 0;
    };


    const getTemperatureImage = (temp) => {
        if (temp <= 0) return 'temp-cold.svg';
        if (temp > 0 && temp <= 20) return 'temp-mid.svg';
        return 'temp-hot.svg';
    };

    // Map cloud descriptions to your custom images
    const getCloudImage = (desc) => {
        const lower = desc.toLowerCase();
        if (lower.includes("clear")) return "no_clouds_sun.png";
        if (lower.includes("partly")) return "light_clouds_sun.png";
        if (lower.includes("scattered")) return "moderate_clouds_sun.png";
        if (lower.includes("some clouds")) return "light_clouds_sun.png";
        if (lower.includes("heavy cloud cover")) return "heavy_clouds_sun.png";
        if (lower.includes("overcast")) return "overcast.png";
        if (lower.includes("rain")) return "light_rain.png";
        if (lower.includes("storm")) return "heavy_rain.png";
        if (lower.includes("snow")) return "blizzard.png"; // or "snow.png", etc.

        // Default fallback
        return "cloudy.png";
    };

    // In case you want to represent wind direction with an image or text
    // you can do that here. If you want to keep it textual, that's fine.
    function getWindImage(desc) {
        switch (desc) {
            case 0:
                return "wi-wind-beaufort-0.svg";
            case 1:
                return "wi-wind-beaufort-1.svg";
            case 2:
                return "wi-wind-beaufort-2.svg"

            case 3:
                return "wi-wind-beaufort-3.svg";
            case 4:
                return "wi-wind-beaufort-4.svg";
            case 5:
                return "wi-wind-beaufort-5.svg"


            case 6:
                return "wi-wind-beaufort-6.svg";
            case 7:
                return "wi-wind-beaufort-7.svg";
            case 8:
                return "wi-wind-beaufort-8.svg"

            case 9:
                return "wi-wind-beaufort-9.svg";
            case 10:
                return "wi-wind-beaufort-10.svg";
            case 11:
                return "wi-wind-beaufort-11.svg"

            case 12:
                return "wi-wind-beaufort-12.svg";

            default:
                return "wi-wind-beaufort-1.svg";
        }
    }

    const currentWeather = weather.Weather;
    const tomorrowWeather = weather.WeatherTomorrow;

    return (
        <div className="weather-forecast-container">
              <h4 className="text-uppercase fw-bolder text-center pt-3">
        Weather
      </h4>
            <div className="border p-2">
                {/* Today's Forecast */}
                <div className="col-12 text-capitalize fw-bolder text-start border-bottom">
                    Today's forecast
                </div>
                <table className="table table-sm table-responsive table-borderless">
                    <tbody>
                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Temperature
                            </th>
                            <td className="bg-transparent text-white">
                                {/* If you have a temperature image, show it */}
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/${getTemperatureImage(
                                        currentWeather.Temperature
                                    )}`}
                                    alt="temp"
                                    style={{ width: "24px", height: "24px" }}
                                />
                                &nbsp; {currentWeather.Temperature}°C
                            </td>
                        </tr>
                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Clouds
                            </th>
                            <td className="bg-transparent text-white text-capitalize">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/${getCloudImage(
                                        currentWeather.CloudDescription
                                    )}`}
                                    alt="cloud"
                                    style={{ width: "24px", height: "24px" }}
                                />
                                &nbsp; {currentWeather.CloudDescription}
                            </td>
                        </tr>
                        <tr>
                            <th className="bg-transparent text-white align-middle" scope="row">
                                Winds
                            </th>
                            <td className="bg-transparent text-white">

                                {/* Wind speed icon */}
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/weather/${getWindImage(
                                        currentWeather.WindAtGroundLevel.Speed
                                    )}`}
                                    alt="wind speed"
                                    style={{ width: "40px", height: "40px", filter: "invert(100%)", margin:"0 -5px 0 -5px"}}
                                />
                                &nbsp;

                                {/* Arrow + bearing stacked */}
                                {(() => {
                                    const originalBearing = parseBearing(currentWeather.WindDescription);
                                    const reversedBearing = (originalBearing + 180) % 360;
                                    return (
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                verticalAlign: "middle", // optional
                                            }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/weather-icons/weather/wi-direction-up.svg`}
                                                alt="wind direction arrow"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    filter: "invert(100%)",
                                                    transform: `rotate(${reversedBearing}deg)`,
                                                }}
                                            />
                                            <div>From {originalBearing}°</div>
                                        </div>
                                    );
                                })()}
                                &nbsp;

                                {/* Remaining text: e.g. "light winds from the northwest" */}
                                {(() => {
                                    // Remove parentheses, e.g. "(332°, ~3m/s)"
                                    let directionText = currentWeather.WindDescription.replace(
                                        /\(\d+°[^)]*\)/,
                                        ""
                                    ).trim();
                                    return directionText;
                                })()}
                            </td>
                        </tr>


                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Precipitation
                            </th>
                            <td className="bg-transparent text-white">None</td>
                        </tr>
                    </tbody>
                </table>

                {/* Tomorrow's Forecast */}
                <div className="col-12 text-capitalize fw-bolder text-start border-bottom">
                    Tomorrow's forecast
                </div>
                <table className="table table-sm table-responsive table-borderless">
                    <tbody>
                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Temperature
                            </th>
                            <td className="bg-transparent text-white">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/${getTemperatureImage(
                                        tomorrowWeather.Temperature
                                    )}`}
                                    alt="temp"
                                    style={{ width: "24px", height: "24px" }}
                                />
                                &nbsp; {tomorrowWeather.Temperature}°C
                            </td>
                        </tr>
                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Clouds
                            </th>
                            <td className="bg-transparent text-white text-capitalize">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/${getCloudImage(
                                        tomorrowWeather.CloudDescription
                                    )}`}
                                    alt="cloud"
                                    style={{ width: "24px", height: "24px" }}
                                />
                                &nbsp; {tomorrowWeather.CloudDescription}
                            </td>
                        </tr>
                        <tr>
                            <th className="bg-transparent text-white align-middle" scope="row">
                                Winds
                            </th>
                            <td className="bg-transparent text-white">

                                {/* Wind speed icon */}
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/weather-icons/weather/${getWindImage(
                                        tomorrowWeather.WindAtGroundLevel.Speed
                                    )}`}
                                    alt="wind speed"
                                    style={{ width: "40px", height: "40px", filter: "invert(100%)", margin:"0 -5px 0 -5px"}}
                                />
                                &nbsp;

                                {/* Arrow + bearing stacked */}
                                {(() => {
                                     const originalBearing = parseBearing(tomorrowWeather.WindDescription);
                                     const reversedBearing = (originalBearing + 180) % 360;
                                    return (
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                verticalAlign: "middle", // optional
                                            }}
                                        >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/weather-icons/weather/wi-direction-up.svg`}
                                                alt="wind direction arrow"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    filter: "invert(100%)",
                                                    transform: `rotate(${reversedBearing}deg)`,
                                                }}
                                            />
                                            <div>From {originalBearing}°</div>
                                        </div>
                                    );
                                })()}
                                &nbsp;

                                {/* Remaining text: e.g. "light winds from the northwest" */}
                                {(() => {
                                    // Remove parentheses, e.g. "(332°, ~3m/s)"
                                    let directionText = tomorrowWeather.WindDescription.replace(
                                        /\(\d+°[^)]*\)/,
                                        ""
                                    ).trim();
                                    return directionText;
                                })()}
                            </td>
                        </tr>
                        <tr>
                            <th className="bg-transparent text-white" scope="row">
                                Precipitation
                            </th>
                            <td className="bg-transparent text-white">None</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

DisplayWeatherForecast.propTypes = {
    weather: PropTypes.shape({
        Weather: PropTypes.shape({
            Temperature: PropTypes.number.isRequired,
            CloudDescription: PropTypes.string.isRequired,
            WindDescription: PropTypes.string.isRequired,
        }).isRequired,
        WeatherTomorrow: PropTypes.shape({
            Temperature: PropTypes.number.isRequired,
            CloudDescription: PropTypes.string.isRequired,
            WindDescription: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default DisplayWeatherForecast;
