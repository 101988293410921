import React, { useState } from "react";
import "../styles/SpoilerText/SpoilerText.css";

function SpoilerText({ children }) {
  const [revealed, setRevealed] = useState(false);

  const toggleSpoiler = () => {
    setRevealed(!revealed);
  };

  return (
    <span 
      className={`spoiler ${revealed ? "revealed" : ""}`} 
      onClick={toggleSpoiler}
      title={!revealed ? "Click to reveal" : ""}
      style={{ position: "relative", display: "inline-block", cursor: "pointer" }}
    >
      {!revealed && (
        <img
          src={`${process.env.PUBLIC_URL}/images/secret.png`}
          alt="Spoiler"
          className="spoiler-image p-3"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
            pointerEvents: "none", // Prevent the image from blocking clicks
            opacity: 0.8, // Adjust opacity if needed
          }}
        />
      )}
      <span className="spoiler-text" style={{ visibility: revealed ? "visible" : "hidden" }}>
        {children}
      </span>
    </span>
  );
}

export default SpoilerText;
