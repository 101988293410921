import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../Modal"; // Import your CustomModal component
import "../../styles/Awards/awards.css";

export default function PilotAwards({ pilotData }) {
  // Local state to store final merged awards (pilot's existing + from sorties)
  const [allAwards, setAllAwards] = useState(pilotData?.Awards || []);
  const [rankConfig, setRankConfig] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);

  // Track load states for awards (key = index in allAwards, value = boolean)
  const [awardDollStates, setAwardDollStates] = useState({});
  const [awardRibbonStates, setAwardRibbonStates] = useState({});

  useEffect(() => {
    // Fetch rank config
    const fetchRankConfig = async () => {
      try {
        const response = await axios.get(
          "https://il2statsapi.combatbox.net/api/rankconfig"
        );
        setRankConfig(response.data);
      } catch (err) {
        console.error("Error fetching rank config:", err);
      }
    };
    fetchRankConfig();
  }, []);

  useEffect(() => {
    // Fetch sorties by persona_id; merge new awards
    if (!pilotData?.persona_id) return;

    const fetchSortiesAndMergeAwards = async () => {
      try {
        const response = await axios.get(
          `https://il2statsapi.combatbox.net/api/sorties?persona=${pilotData.persona_id}`
        );
        if (Array.isArray(response.data)) {
          const newAwardsFromSorties = [];
          response.data.forEach((sortie) => {
            if (Array.isArray(sortie.Awards)) {
              sortie.Awards.forEach((awardObj) => {
                newAwardsFromSorties.push({
                  name: awardObj.Name || "",
                  image: awardObj.Image || "",
                  description: awardObj.Description || "",
                  requirements: awardObj.Requirements || "",
                });
              });
            }
          });

          const mergedAndDeduped = deduplicateAwards(allAwards, newAwardsFromSorties);
          setAllAwards(mergedAndDeduped);
        }
      } catch (error) {
        console.error("Error fetching sorties or merging awards:", error);
      }
    };

    fetchSortiesAndMergeAwards();
  }, [pilotData?.persona_id]);

  function deduplicateAwards(existingAwards, newAwards) {
    const map = new Map();
    existingAwards.forEach((aw) => {
      const key = (aw.name || "") + "#" + (aw.image || "");
      map.set(key, aw);
    });
    newAwards.forEach((aw) => {
      const key = (aw.name || "") + "#" + (aw.image || "");
      map.set(key, aw);
    });
    return Array.from(map.values());
  }

  const countryMap = {
    101: "Sov",
    102: "Raf",
    103: "Usa",
    201: "Ger",
  };

  const getPilotRankImagePath = (pilotData) => {
    if (!pilotData) return "";
    const folderName = countryMap[pilotData.country];
    // Remove extension from RankImage
    const sanitizedRankImage = pilotData.RankImage.replace(/\.[^.]+$/, "");
    return `${process.env.PUBLIC_URL}/images/pilot-ranks/${folderName}/${sanitizedRankImage}-doll.png`;
  };

  const getPilotAwardImagePath = (pilotData, awardImage, suffix) => {
    if (!pilotData || !pilotData.PilotPortrait) return "";
    const folderName = countryMap[pilotData.country];
    if (suffix === "doll") {
      return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}-doll.png`;
    }
    if (suffix === "ribbon") {
      return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}-ribbon.png`;
    }
    return `${process.env.PUBLIC_URL}/images/pilot-awards/${folderName}/${awardImage}.webp`;
  };

  // Build "doll" overlay images
  const validDollImages = allAwards
    ?.map((award, index) => {
      if (awardDollStates[index] === false) return null;
      const src = getPilotAwardImagePath(pilotData, award.image, "doll");
      return (
        <img
          key={index}
          src={src}
          alt={award.name}
          className="paper-doll"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 3 + index,
          }}
          onError={() =>
            setAwardDollStates((prev) => ({ ...prev, [index]: false }))
          }
        />
      );
    })
    .filter(Boolean);

  // Build "ribbon" images
  const ribbonImages = allAwards
    ?.map((award, index) => {
      if (awardRibbonStates[index] === false) return null;
      const src = getPilotAwardImagePath(pilotData, award.image, "ribbon");
      return (
        <img
          key={index}
          src={src}
          alt={award.name}
          className="awards-ribbon"
          onError={() =>
            setAwardRibbonStates((prev) => ({ ...prev, [index]: false }))
          }
        />
      );
    })
    .filter(Boolean);

  const hasAnyRibbons = ribbonImages && ribbonImages.length > 0;

  return (
    <div className={`pt-5 border bg-USSR`}>
      <div className="container-fluid">
        <div className="text-start">
          <h3>UNIFORM</h3>
        </div>

        {/* Overlay Display Section */}
        <div className="text-center border" style={{ position: "relative" }}>
          {/* Background Image */}
          <img
            src={`${process.env.PUBLIC_URL}/images/pilot-awards/paper-dolls/${countryMap[
              pilotData.country
            ].toLowerCase()}-paperdoll.png`}
            alt="Paperdoll"
            className="paper-doll"
          />

          {/* Single rank overlay */}
          <img
            src={getPilotRankImagePath(pilotData)}
            className="paper-doll"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2,
            }}
          />

          {/* Multiple awards overlay (only render valid images) */}
          {validDollImages}

          {/* RIBBONS */}
          {hasAnyRibbons && (
            <div className="col-12 awards-ribbon-div">{ribbonImages}</div>
          )}
        </div>

        {/* Medal Grid Section */}
        <div className="text-start mt-5">
          <h3 className="text-uppercase">Medals Box</h3>
        </div>
        <div className="text-center border awards-box mb-5">
          <div className="d-flex flex-wrap justify-content-center">
            {allAwards?.map((award, index) => {
              const src = getPilotAwardImagePath(pilotData, award.image, false);
              const isMentionedInDispatches = award.name === "Mentioned in Dispatches";

              return (
                // Position relative so we can absolutely-position the pilot name
                <div
                  key={index}
                  style={{
                    position: "relative",
                    flex: "0 0 25%", // 4 items per row
                    maxWidth: "25%",
                    padding: "5px",
                  }}
                >
                  <img
                    src={src}
                    alt={award.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedAward(award)}
                  />

                  {isMentionedInDispatches && (
                    <div
                      className="awards-dispatches-medals-box"
                    >
                      {pilotData.PilotName}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Modal for Award Details */}
      {selectedAward && (
        <Modal
          show={!!selectedAward}
          onHide={() => setSelectedAward(null)}
          title={selectedAward.name}
          body={
            <div>
              {/* Wrap the image in a position:relative container */}
              <div style={{ position: "relative" }}>
                <img
                  src={getPilotAwardImagePath(pilotData, selectedAward.image, false)}
                  alt={selectedAward.name}
                  style={{ height: "100%", width: "100%", display: "block" }}
                />

                {/* 
            If it's "Mentioned in Dispatches," overlay the pilot name.
            Adjust the positioning & styling as needed.
          */}
                {selectedAward.name === "Mentioned in Dispatches" && (
                  <div
                 className="awards-dispatches-modal"
                  >
                    {pilotData.PilotName}
                  </div>
                )}
              </div>

              <p>{selectedAward.description}</p>
              <p>
                <strong>Requirements:</strong> {selectedAward.requirements}
              </p>
            </div>
          }
          size="lg"
        />
      )}

    </div>
  );
}
