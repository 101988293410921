
export default function getAmmoTypeMapping() {
  const ammoTypeMapping = {

    "BULLET_ROM_13x99_AP": ["13.2 mm Hotchkiss (AP)"],
    "SHELL_GER_20x77_AP": ["20 mm MG 151 (Short)"],
    "BULLET_ENG_11X59_AP": ["11 mm Vickers (AP)"],
    "BULLET_ENG_7-7X56_AP": [".303 British (AP)"],
    "BULLET_ENG_7-7x56_APSMK": [".303 British (AP)"],
    "BULLET_GBR_11X59_AP": ["11 mm Vickers (AP)"],
    "BULLET_GER_13X64_AP": ["13 mm MG 131 (AP)"],
    "BULLET_GER_13X64_HE": ["13 mm MG 131 (HE)"],
    "BULLET_GER_7-92X57_AP": ["7.92 mm Mauser (AP)"],
    "BULLET_GER_7-92X57_APSMK": ["7.92 mm Mauser (AP)"],
    "BULLET_GER_792X57_SS": ["7.92 mm Mauser (SS)"],
    "BULLET_ITA_12-7X81_AP": ["12.7 mm Breda (AP)"],
    "BULLET_ITA_12-7X81_HE": ["12.7 mm Breda (HE)"],
    "BULLET_ITA_7-7X56_AP": ["7.7 mm Breda (AP)"],
    "BULLET_PISTOL": ["Pistol bullet"],
    "BULLET_RUS_12-7X108_AP": ["12.7 mm UB (AP)"],
    "BULLET_RUS_12-7X108_HE": ["12.7 mm UB (HE)"],
    "BULLET_RUS_7-62X54_AP": ["7.62 mm ShKAS (AP)"],
    "BULLET_RUS_7-62X54_APSMK": ["7.62 mm ShKAS (AP)"],
    "BULLET_USA_12-7X99_AP": [".50 BMG (AP)"],
    "BULLET_USA_7-62X63_AP": [".30-06 Springfield (AP)"],
    "NPC_BULLET_GER_7-92": ["7.92 mm MG 34 (AP)"],
    "NPC_BULLET_GER_7-92_AP_SHORT": ["7.92 mm Mauser (Short)"],
    "NPC_BULLET_RUS_7-62_AP_SHORT": ["7.62 mm Soviet (Short)"],
    "NPC_BULLET_RUS_12-7_AP": ["12.7 mm Soviet (AP)"],
    "NPC_BULLET_RUS_12-7_AP_SHORT": ["12.7 mm Soviet (Short)"],
    "NPC_BULLET_RUS_7-62_AP": ["7.62 mm Soviet (AP)"],
    "NPC_BULLET_RUS_7-62X4": ["7.62 mm Soviet (Quad)"],
    "NPC_BULLET_USA_12-7_AP": [".50 BMG (AP)"],
    "NPC_BULLET_USA_7-62_AP_SHORT": [".30-06 Springfield (Short)"],
    "NPC_SHELL_ENG_20_AP": ["20 mm Hispano (AP)"],
    "NPC_SHELL_ENG_20_HE": ["20 mm Hispano (HE)"],
    "NPC_SHELL_RUS_122_HE": ["122 mm Soviet (HE)"],
    "NPC_SHELL_RUS_130_HE": ["130 mm Soviet (HE)"],
    "NPC_SHELL_RUS_76_NAVAL_CV": ["76.2 mm 34-K (APHE)"],
    "NPC_SHELL_USA_155_HE": ["155 mm M107 (HE)"],
    "NPC_SHELL_USA_90_CV": ["90 mm M2 (APHE)"],
    "NPC_SHELL_USA_90_HE": ["90 mm M2 (HE)"],
    "SHELL_GER_15X96_AP": ["15 mm MG 151 (AP)"],
    "SHELL_GER_15X96_HE": ["15 mm MG 151 (HE)"],
    "SHELL_GER_20X82_AP": ["20 mm MG 151 (AP)"],
    "SHELL_GER_20X82_HE": ["20 mm MG 151 (HE)"],
    "SHELL_GER_37X263_AP": ["3.7 cm FlaK/BK (AP)"],
    "SHELL_GER_37X263_HE": ["3.7 cm FlaK/BK (HE)"],
    "SHELL_RUS_122_HE": ["122 mm Soviet (HE)"],
    "SHELL_RUS_122_HT": ["122 mm Soviet (HEAT)"],
    "SHELL_RUS_20X99_AP": ["20 mm ShVAK (AP)"],
    "SHELL_RUS_20X99_HE": ["20 mm ShVAK (HE)"],
    "SHELL_RUS_23X152_AP": ["VYA-23 (AP)"],
    "SHELL_RUS_23X152_HE": ["VYA-23 (HE)"],
    "SHELL_RUS_37X195_AP": ["SH-37 (AP)"],
    "SHELL_RUS_37X195_HE": ["SH-37 (HE)"],
    "SHELL_RUS_37X198_AP": ["NS-37 (AP)"],
    "SHELL_RUS_37X198_HE": ["NS-37 (HE)"],
    "SHELL_RUS_76_NAVAL_HE": ["76.2 mm 34-K (HE)"],
    "SHELL_USA_37X145_AP": ["37 mm M4 (AP)"],
    "SHELL_USA_37X145_HE": ["37 mm M4 (HE)"],
    "SHELL_USA_76_CV": ["76 mm M5 (APHE)"],
    "SHELL_USA_76_HE": ["76 mm M5 (HE)"],
    "NPC_SHELL_ENG_76_HE": ["76 mm British (HE)"],
    "NPC_SHELL_GER_105_HE": ["105 mm German (HE)"],
    "NPC_SHELL_GER_20_AP": ["20 mm FlaK 38 (AP)"],
    "NPC_SHELL_GER_20_HE": ["20 mm FlaK 38 (HE)"],
    "NPC_SHELL_GER_37_CV": ["3.7 cm PaK 36 (APHE)"],
    "NPC_SHELL_GER_37_HE": ["3.7 cm PaK 36  (HE)"],
    "NPC_SHELL_GER_37X250_AP": ["3.7 cm FlaK (AP)"],
    "NPC_SHELL_GER_37X250_HE": ["3.7 cm FlaK (HE)"],
    "NPC_SHELL_GER_37X263_AP": ["3.7 cm FlaK 36/43 (AP)"],
    "NPC_SHELL_GER_37X263_HE": ["3.7 cm FlaK 36/43 (HE)"],
    "NPC_SHELL_GER_50_AP": ["5 cm German (AP)"],
    "NPC_SHELL_GER_50_HE": ["5 cm German (HE)"],
    "NPC_SHELL_GER_75_AP": ["7.5 cm PaK 40 (AP)"],
    "NPC_SHELL_GER_75_HE": ["7.5 cm PaK 40 (HE)"],
    "NPC_SHELL_GER_77_HE": ["77 mm German (HE)"],
    "NPC_SHELL_GER_88_AP": ["88 mm FlaK 37 (AP)"],
    "NPC_SHELL_GER_88_HE": ["88 mm FlaK 37 (HE)"],
    "NPC_SHELL_RUS_100_HE": ["100 mm Soviet (AP)"],
    "NPC_SHELL_RUS_152_HE": ["152 mm Soviet (HE)"],
    "NPC_SHELL_RUS_25_AP": ["25 mm 72-K (AP)"],
    "NPC_SHELL_RUS_25_HE": ["25 mm 72-K (HE)"],
    "NPC_SHELL_RUS_37_AP": ["37 mm Soviet (AP)"],
    "NPC_SHELL_RUS_37_HE": ["37 mm Soviet (HE)"],
    "NPC_SHELL_RUS_45_AP": ["45 mm Soviet (AP)"],
    "NPC_SHELL_RUS_45_CV": ["45 mm Soviet (APHE)"],
    "NPC_SHELL_RUS_45_HE": ["45 mm Soviet (HEAT)"],
    "NPC_SHELL_RUS_57_AP": ["57 mm Soviet (AP)"],
    "NPC_SHELL_RUS_57_CV": ["57 mm Soviet (APHE)"],
    "NPC_SHELL_RUS_57_HE": ["57 mm Soviet (HE)"],
    "NPC_SHELL_RUS_76_AP": ["76 mm Soviet (AP)"],
    "NPC_SHELL_RUS_76_HE": ["76 mm Soviet (HE)"],
    "NPC_SHELL_RUS_76_NAVAL_HE": ["76 mm Soviet Naval (HE)"],
    "NPC_SHELL_RUS_85_AP": ["85 mm Soviet (AP)"],
    "NPC_SHELL_RUS_85_CV": ["85 mm Soviet (APHE)"],
    "NPC_SHELL_RUS_85_HE": ["85 mm Soviet (HE)"],
    "SHELL_ENG_115_HE": ["115 mm British"],
    "SHELL_ENG_20X110_AP": ["20 mm Hispano (AP)"],
    "SHELL_ENG_20X110_HE": ["20 mm Hispano (HE)"],
    "SHELL_ENG_40X158_AP": ["40 mm Vickers (AP)"],
    "SHELL_ENG_40X158_HE": ["40 mm Vickers (HE)"],
    "SHELL_GER_105_HE": ["105 mm German (HE)"],
    "SHELL_GER_105_HT": ["105 mm German (HEAT)"],
    "SHELL_GER_150_HE": ["150 mm German (HE)"],
    "SHELL_GER_30X184_AP": ["30 mm MK 108 (AP)"],
    "SHELL_GER_30X184_HE": ["30 mm MK 108 (HE)"],
    "SHELL_GER_50_CV": ["5 cm KwK 39 (APHE)"],
    "SHELL_GER_50_HE": ["5 cm KwK 39  (HE)"],
    "SHELL_GER_50_HV": ["5 cm KwK 39  (HVAP)"],
    "SHELL_GER_75_CV": ["7.5 cm KwK 40 (APHE)"],
    "SHELL_GER_75_HE": ["7.5 cm KwK 40 (HE)"],
    "SHELL_GER_75_HT": ["7.5 cm KwK 40 (HEAT)"],
    "SHELL_GER_75_HV": ["7.5 cm KwK 40 (HVAP)"],
    "SHELL_GER_88_AP": ["88 mm German (AP)"],
    "SHELL_GER_88_CV": ["88 mm German (APHE)"],
    "SHELL_GER_88_HE": ["88 mm German (HE)"],
    "SHELL_GER_88_HE_KWK43": ["88 mm KwK 43 (HE)"],
    "SHELL_GER_88_HV": ["88 mm German (HVAP)"],
    "SHELL_RUS_100_HE": ["100 mm Soviet (HE)"],
    "SHELL_RUS_130_CV": ["100 mm Soviet (APHE)"],
    "SHELL_RUS_130_HE": ["130 mm Soviet (HE)"],
    "SHELL_RUS_152_CV": ["152 mm Soviet (APHE)"],
    "SHELL_RUS_152_HE": ["152 mm Soviet (HE)"],
    "SHELL_RUS_25X218_AP": ["25 mm 72-K (AP)"],
    "SHELL_RUS_25X218_HE": ["25 mm 72-K (HE)"],
    "SHELL_RUS_76_AP": ["76 mm Soviet (AP)"],
    "SHELL_RUS_76_CV": ["76 mm Soviet (APHE)"],
    "SHELL_RUS_76_HE": ["76 mm Soviet (HE)"],
    "SHELL_RUS_76_HV": ["76 mm Soviet (HVAP)"],
    "SHELL_USA_155_HE": ["155 mm M107 (HE)"],
    "SHELL_USA_75_AP": ["75 mm M3 (AP)"],
    "SHELL_USA_75_CV": ["75 mm M3 (APHE)"],
    "SHELL_USA_75_HE": ["75 mm M3 (HE)"],
    "BOMB_FRA_20KG": ["20 kg Bomb French"],
    "BOMB_FRA_40KG": ["40 kg Bomb French"],
    "BOMB_FRA_8KG": ["8 kg Bomb French"],
    "BOMB_GBR_112LB": [" H.E.R.L. 112 lbs"],
    "BOMB_GBR_20LB": ["24 lb Cooper"],
    "BOMB_GBR_230LB": ["230 lb bomb British"],
    "BOMB_GBR_24LB": ["24 lb Cooper"],
    "BOMB_GBR_GPB250": ["250 lb GP"],
    "BOMB_GBR_GPB500": ["500 lb GP"],
    "BOMB_GBR_MC1000": ["1000 lb MC"],
    "BOMB_GBR_MC250MK2": ["250 lb MC Mk 2"],
    "BOMB_GBR_MC500": ["500 lb MC"],
    "BOMB_GBR_MC500MK7": ["500 lb MC Mk 7"],
    "BOMB_GER_10KG": ["10 kg P.u.W"],
    "BOMB_GER_12KG": ["12.5 kg P.u.W"],
    "BOMB_GER_50KG": ["50 kg P.u.W "],
    "BOMB_GER_SC1000": ["SC 1000"],
    "BOMB_GER_SC1000_190": ["SC 1000 (Fw 190)"],
    "BOMB_GER_SC1800": ["SC 1800"],
    "BOMB_GER_SC250": ["SC 250"],
    "BOMB_GER_SC250_87": ["SC 250 (Ju 87)"],
    "BOMB_GER_SC2500": ["SC 2500"],
    "BOMB_GER_SC50": ["SC 50"],
    "BOMB_GER_SC500": ["SC 500"],
    "BOMB_GER_SC500_87": ["SC 500 (Ju 87)"],
    "BOMB_GER_SD70": ["SD 70"],
    "BOMB_ITA_100T": ["100-T Italian"],
    "BOMB_ITA_50T": ["50-T Italian"],
    "BOMB_RUS_FAB100M": ["FAB-100M"],
    "BOMB_RUS_FAB100M_P39": ["FAB-100M (P39)"],
    "BOMB_RUS_FAB250SV": ["FAB-250sv"],
    "BOMB_RUS_FAB250SV_P40": ["FAB-250sv (P40)"],
    "BOMB_RUS_FAB250TSK": ["FAB-250"],
    "BOMB_RUS_FAB500M": ["FAB-500M"],
    "BOMB_RUS_FAB500M_P40": ["FAB-500M (P40)"],
    "BOMB_RUS_FAB50SV": ["FAB-50sv"],
    "BOMB_USA_M64": ["500 lb M64"],
    "BOMB_USA_M65": ["1000 lb M65"],
    "BOMB_USA_M66": ["2000 lb M66"],
    "CLUSTER_RUS_PTAB2515": ["PTAB-2.5-1.5"],
    "CLUSTER_RUS_PTAB2515_HIT": ["PTAB-2.5-1.5"],
    "NPC_TORPEDO_53-38": ["Type 53 Torpedo"],
    "NPC_TORPEDO_G7a-53,0,G7a": ["G7a Torpedo"],
    "RKT_FRA_LEPRIEUR": ["Le Prieur"],
    "RKT_FRA_LEPRIEURHE": ["Le Prieur (HE)"],
    "RKT_GBR_RP3AP": ["RP-3 (AP)"],
    "RKT_GBR_RP3AP_HIT": ["RP-3 (AP)"],
    "RKT_GBR_RP3AP_MK3": ["RP-3 Mk 3 (AP)"],
    "RKT_GBR_RP3HE": ["RP-3 (HE)"],
    "RKT_GBR_RP3HE_DOUBLE": ["RP-3 double (HE)"],
    "RKT_GBR_RP3HE_MK3": ["RP-3 Mk 3 (HE)"],
    "RKT_GBR_RP3MK3AP": ["RP-3 Mk3 (AP)"],
    "RKT_GBR_RP3MK3HE": ["RP-3 Mk 3 (HE)"],
    "RKT_GBR_RP3MK3TLAP": ["RP-3 Mk 3 (AP)"],
    "RKT_GBR_RP3MK3TLHE": ["RP-3 Mk 3 (HE)"],
    "RKT_GBR_RP3MK3TUAP": ["RP-3 Mk 3 (AP)"],
    "RKT_GBR_RP3MK3TUHE": ["RP-3 Mk 3 (HE)"],
    "RKT_GER_PB1": ["PB1"],
    "RKT_GER_PB1_HIT": ["PB1"],
    "RKT_GER_PB1_M8": ["PB1 M8"],
    "RKT_GER_R4M": ["R4M"],
    "RKT_GER_WGR21": ["Werfer-Granate 21"],
    "RKT_GER_WGR21_1000": ["Werfer-Granate 21"],
    "RKT_GER_WGR21_1200": ["Werfer-Granate 21"],
    "RKT_GER_WGR21_800": ["Werfer-Granate 21"],
    "RKT_RUS_RBS82": ["RBS-82"],
    "RKT_RUS_RBS82_HIT": ["RBS-82"],
    "RKT_RUS_ROFS132": ["ROFS-132"],
    "RKT_RUS_ROS82": ["ROS-82"],
    "RKT_RUS_ROS82_1000,0,ROS-82,РО": ["ROS-82"],
    "RKT_RUS_ROS82_600,0,ROS-82,РО": ["ROS-82"],
    "RKT_RUS_ROS82_800,0,ROS-82,РО": ["ROS-82"],
    "RKT_USA_M8": ["4.5 in Rocket M8"],
    "Rocket 280 MM HE": ["Rocket 280 mm HE"],

    // Add more airfields and their images as needed
  };
  
  const normalizedMapping = {};
  for (const key in ammoTypeMapping) {
    normalizedMapping[key.toUpperCase()] = ammoTypeMapping[key];
  }

  return (name) => {
    if (typeof name !== "string") {
      console.error("Invalid key passed to mapAmmoType:", name);
      return "Unknown Ammo"; // Fallback
    }

    const normalizedName = name.toUpperCase().trim();
    const mapped = normalizedMapping[normalizedName];

    if (!mapped) {
      console.warn(`No mapping found for ammo type: "${name}"`);
    }

    return mapped || name.trim();
  };
}

