// src/pages/MemorialWall.jsx
import React from 'react';
import PilotStatsList from '../components/PilotStatsList';

function MemorialWall() {
  // "Memorial" means alive = false
  return (
    <div>
      <PilotStatsList alive={false} headingTitle="Memorial Wall" />
    </div>
  );
}

export default MemorialWall;
