import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RemainingSupplyGraph from '../RemainingSupplyGraph'; // Adjust path as needed

function DisplayObjectivesList({ objectives, showActiveOnly }) {
  const [sortConfig, setSortConfig] = useState({ key: 'SupplyLevel', direction: 'decending' });

  const sortedObjectives = [...objectives].sort((a, b) => {
    const direction = sortConfig.direction === 'ascending' ? 1 : -1;
  
    // First sort by MaxSupplyLevel
    if (a.MaxSupplyLevel !== b.MaxSupplyLevel) {
      return (a.MaxSupplyLevel - b.MaxSupplyLevel) * direction;
    }
    // If MaxSupplyLevel is the same, sort by SupplyLevel
    return (a.SupplyLevel - b.SupplyLevel) * direction;
  });
  

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderRows = () => {
    const filteredObjectives = showActiveOnly
      ? sortedObjectives.filter((objective) => objective.ActiveToday)
      : sortedObjectives;

    return filteredObjectives.map((objective, index) => {
      const capturableClass = objective.CapturableToday ? 'capturable-row' : '';

      return (
        <tr key={index} className={`selectable-row ${capturableClass}`}>
          <th scope="row">
            <Link
              to={`/objective/${encodeURIComponent(objective.Name)}`}
              state={{ objective }}
              className="text-white text-decoration-none"
            >
              {objective.Name}
            </Link>
          </th>
          <td>
            <RemainingSupplyGraph
              supplyLevel={objective.SupplyLevel}
              maxSupplyLevel={objective.MaxSupplyLevel}
            />
          </td>
          <td className="text-capitalize">
            {objective.Type.charAt(0) + objective.Type.slice(1).toLowerCase()}
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-bordered table-dark table-responsive table-hover table-striped">
      <thead>
        <tr>
          <th
            scope="col"
            style={{ cursor: 'pointer' }}
            onClick={() => requestSort('Name')}
          >
            Name {sortConfig.key === 'Name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
          <th
            scope="col"
            style={{ cursor: 'pointer' }}
            onClick={() => requestSort('SupplyLevel')}
          >
            Supply {sortConfig.key === 'SupplyLevel' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
          <th
            scope="col"
            style={{ cursor: 'pointer' }}
            onClick={() => requestSort('Type')}
          >
            Type {sortConfig.key === 'Type' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

DisplayObjectivesList.propTypes = {
  objectives: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      SupplyLevel: PropTypes.number.isRequired,
      MaxSupplyLevel: PropTypes.number.isRequired,
      Type: PropTypes.string,
      CapturableToday: PropTypes.bool,
      ActiveToday: PropTypes.bool.isRequired,
    })
  ).isRequired,
  showActiveOnly: PropTypes.bool, // Determines whether to show only active objectives
};

DisplayObjectivesList.defaultProps = {
  showActiveOnly: false, // Default to showing all objectives
};

export default DisplayObjectivesList;
