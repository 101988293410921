// src/components/PilotDetails/CombatRecordModal.jsx

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import getPhotoMapping from "../getPhotoMapping";
import axios from "axios";
import getAmmoTypeMapping from "../getAmmoTypeMapping";

const getSortieStatusText = (status) => {
  const statusMap = {
    landed_on_base: "Landed at base",
    aborted: "Aborted",
    disconnected: "Disconnected",
    crash_landed_on_base: "Crash landed at base",
    destroyed_before_take_off: "Destroyed before take off",
    bailed_out: "Bailed out",
    in_flight: "In flight",
    crash_landed_off_base: "Crash landed near base",
  };
  return statusMap[status] || "Unknown";
};

const calculateFiredShotsAndHitPercentage = (ammo, payload) => {
  let ammoData;
  try {
    ammoData = JSON.parse(ammo);
  } catch (error) {
    console.error("Error parsing ammo JSON:", error);
    return {
      totalBullets: 0,
      usedCartridges: 0,
      percentageFired: 0,
      hitBullets: 0,
      hitPercentage: 0,
    };
  }

  const payloadRegex = /\[([0-9]+)\]/g;
  const payloadMatches = [...payload.matchAll(payloadRegex)].map((match) =>
    parseInt(match[1], 10)
  );
  const totalBullets = payloadMatches.reduce((sum, num) => sum + num, 0);

  const usedCartridges = ammoData.used_cartridges || 0;
  const hitBullets = ammoData.hit_bullets || 0;

  const percentageFired =
    totalBullets > 0 ? ((usedCartridges / totalBullets) * 100).toFixed(0) : 0;
  const hitPercentage =
    usedCartridges > 0 ? ((hitBullets / usedCartridges) * 100).toFixed(0) : 0;

  return {
    totalBullets,
    usedCartridges,
    percentageFired,
    hitBullets,
    hitPercentage,
  };
};

const renderAmmoBreakdown = (ammoField, type) => {
  if (!ammoField) return "No data available";
  try {
    const ammoData = JSON.parse(ammoField);

    const dataMap = {
      Bombs: ammoData?.ammo_breakdown?.ordinance?.bombs?.total_hits || {},
      Rockets: ammoData?.ammo_breakdown?.ordinance?.rockets?.total_hits || {},
      Bullets: ammoData?.ammo_breakdown?.total_hits || {},
    };

    const data = dataMap[type] || {};
    if (Object.entries(data).length === 0) return "No data available";

    return (
      <table className="table table-sm table-dark table-borderless">
        <thead>
          <tr>
            <th>Type</th>
            <th>Hits</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, value], index) => (
            <tr key={index}>
              <td>{key.replace(/_/g, " ")}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } catch (error) {
    console.error("Error parsing ammo field:", error);
    return "Invalid ammo data";
  }
};

const renderKillboardTable = (killboardString) => {
  if (!killboardString) return "No data available";
  try {
    const killboardData = JSON.parse(killboardString);
    const sortedKillboardData = Object.entries(killboardData).sort(
      ([keyA], [keyB]) => keyA.localeCompare(keyB)
    );
    return (
      <table className="table table-sm table-dark">
        <thead>
          <tr>
            <th>Type</th>
            <th>Destroyed</th>
          </tr>
        </thead>
        <tbody>
          {sortedKillboardData.map(([name, destroyed], index) => (
            <tr key={index}>
              <td>{name.replace(/_/g, " ")}</td>
              <td>{destroyed}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } catch (error) {
    console.error("Error parsing killboard string:", error);
    return "Invalid killboard data";
  }
};

const CombatRecordModal = ({ show, onHide, selectedSortie }) => {
  const [logEntries, setLogEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log("Selected Sortie Data", selectedSortie);

  // NEW or CHANGED:
  // State for the *opposing* player's sortie & controlling second modal
  const [opposingSortie, setOpposingSortie] = useState(null);
  const [showOpposingModal, setShowOpposingModal] = useState(false);

  useEffect(() => {
    if (selectedSortie) {
      const fetchLogEntries = async () => {
        setIsLoading(true);
        setError(null);

        try {
          const response = await axios.get(
            `https://il2statsapi.combatbox.net/api/logentries?sortie=${selectedSortie.sortie_id}`
          );
          console.log("Sorties Log", response.data);
          setLogEntries(response.data);
        } catch (err) {
          console.error("Failed to fetch log entries:", err);
          setError("Failed to fetch log entries. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchLogEntries();
    }
  }, [selectedSortie]);

  // NEW:
  // Function to fetch the opposing player's sortie details and show a second modal
  const handleOpposingSortieClick = async (opposingSortieId) => {
    console.log("opposing sortie", opposingSortieId);
    if (!opposingSortieId) return;

    try {
      // Example endpoint to fetch the single sortie by ID
      const response = await axios.get(
        `https://il2statsapi.combatbox.net/api/sorties?sortie_id=${opposingSortieId}`
      );

      
      // Suppose it returns an array with one item or just an object
      let data = response.data;
      if (Array.isArray(data) && data.length > 0) {
        data = data[0];
      }

      setOpposingSortie(data);
      setShowOpposingModal(true);
    } catch (err) {
      console.error("Error fetching opposing sortie data:", err);
    }
  };

  if (!selectedSortie) return null;

  const formattedCombatLogDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day} ${month} ${year} ${hours}${minutes}`;
  };

  const photoMapping = getPhotoMapping();
  const planeImages = photoMapping.getReconPhotos(selectedSortie.aircraft_type);

  const ammoStats = calculateFiredShotsAndHitPercentage(
    selectedSortie.ammo,
    selectedSortie.payload
  );
  const { totalBullets, usedCartridges, percentageFired, hitBullets, hitPercentage } =
    ammoStats;

  const mapAmmoType = getAmmoTypeMapping();

  const modalBody = (
    <div className="container-fluid">
      <div className="row">
        <h3>Combat Sortie Details for {selectedSortie.nickname}</h3>
        <div className="col-7">
          {planeImages && planeImages.length > 0 ? (
            planeImages.map((image, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/images/aircraft-icons/${image}`}
                alt={selectedSortie.aircraft_type}
                style={{ height: "200px", width: "auto", marginRight: "10px" }}
              />
            ))
          ) : (
            "No image available"
          )}
        </div>
        <div className="col-5 text-end">
          <strong>Promotion Points</strong>{" "}
          {selectedSortie.promotion_points > 0
            ? `+${selectedSortie.promotion_points}`
            : selectedSortie.promotion_points}
        </div>
      </div>
      <div className="row text-small">
        {/* Plane Selection Details */}
        <div className="col-6">
          <table className="table table-dark table-borderless" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th>Plane</th>
                <td>{selectedSortie.aircraft_type}</td>
              </tr>
              <tr>
                <th>Payload</th>
                <td>
                  {selectedSortie.payload?.split(", ").map((item, idx) => (
                    <div key={idx}>{item}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>Plane Modifications</th>
                <td>
                  {selectedSortie.aircraft_mods?.length > 0 ? (
                    <div>
                      {selectedSortie.aircraft_mods.map((mod, idx) => (
                        <div key={idx}>{mod}</div>
                      ))}
                    </div>
                  ) : (
                    "No modifications"
                  )}
                </td>
              </tr>
              <tr>
                <th>Fuel Taken</th>
                <td>{selectedSortie.fuel}%</td>
              </tr>
              <tr>
                <th>Plane Damage</th>
                <td>{selectedSortie.aircraft_damage_percentage}%</td>
              </tr>
              <tr>
                <th>Flight Time</th>
                <td>{selectedSortie.formatted_flight_time}</td>
              </tr>
              <tr>
                <th>Sortie Status</th>
                <td>{getSortieStatusText(selectedSortie.overall_status)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Sortie Details Stats */}
        <div className="col-6">
          <table className="table table-dark table-bordered text-small" style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th>Available Ammo</th>
                <td>{totalBullets}</td>
              </tr>
              <tr>
                <th>Ammo Used</th>
                <td>{usedCartridges}</td>
              </tr>
              <tr>
                <th>Fired Percentage</th>
                <td>{percentageFired}%</td>
              </tr>
              <tr>
                <th>Ammo Hits</th>
                <td>{hitBullets}</td>
              </tr>
              <tr>
                <th>Hit Percentage</th>
                <td>{hitPercentage}%</td>
              </tr>
              <tr>
                <th>Bomb Hits</th>
                <td>{renderAmmoBreakdown(selectedSortie.ammo, "Bombs")}</td>
              </tr>
              <tr>
                <th>Rocket Hits</th>
                <td>{renderAmmoBreakdown(selectedSortie.ammo, "Rockets")}</td>
              </tr>
              <tr>
                <th>Bullet Hits</th>
                <td>{renderAmmoBreakdown(selectedSortie.ammo, "Bullets")}</td>
              </tr>
              <tr>
                <th>Air Kills</th>
                <td>{selectedSortie.air_kills}</td>
              </tr>
              <tr>
                <th>Assists</th>
                <td>{selectedSortie.air_kill_assists}</td>
              </tr>
              <tr>
                <th>Ground Kills</th>
                <td>{selectedSortie.ground_kills}</td>
              </tr>
              <tr>
                <th>Killboard (PvE)</th>
                <td>{renderKillboardTable(selectedSortie.killboard_pve)}</td>
              </tr>
              <tr>
                <th>Killboard (PvP)</th>
                <td>{renderKillboardTable(selectedSortie.killboard_pvp)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="full-width-bar my-4"></div>
      {/* SORTIE LOG */}
      <div className="row">
        <h3>Combat Sortie Log</h3>
        <div style={{ maxHeight: "600px", overflowY: "scroll", width: "1270px" }}>
          <table
            className="table border-color-gray table-striped table-dark text-small"
            style={{ tableLayout: "fixed", width: "725px" }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#343a40",
                zIndex: 1,
              }}
            >
              <tr>
                <th className="text-start" style={{ width: "7%" }}>
                  <div
                    className="border"
                    style={{
                      margin: "-7px -10px -7px -8px",
                      padding: "6px 0px 6px 8px",
                    }}
                  >
                    Date
                  </div>
                </th>
                <th className="text-start" style={{ width: "7%" }}>
                  <div
                    className="border"
                    style={{
                      margin: "-7px -10px -7px -8px",
                      padding: "6px 0px 6px 8px",
                    }}
                  >
                    Event
                  </div>
                </th>
                <th className="text-start" style={{ width: "7%" }}>
                  <div
                    className="border"
                    style={{
                      margin: "-7px -10px -7px -8px",
                      padding: "6px 0px 6px 8px",
                    }}
                  >
                    Target
                  </div>
                </th>
                <th className="text-start" style={{ width: "10%" }}>
                  <div
                    className="border"
                    style={{
                      margin: "-7px -10px -7px -8px",
                      padding: "6px 0px 6px 8px",
                    }}
                  >
                    Player
                  </div>
                </th>
                <th className="text-start" style={{ width: "10%" }}>
                  <div
                    className="border"
                    style={{
                      margin: "-7px -10px -7px -8px",
                      padding: "6px 0px 6px 8px",
                    }}
                  >
                    Hits
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {logEntries.length > 0 ? (
                logEntries.map((entry, index) => {
                  // rowClass logic
                  const rowClass = (() => {
                    if (typeof entry.action_type === "string") {
                      const typeLower = entry.action_type.toLowerCase();
                      if (["damaged"].includes(typeLower)) return "text-color-green";
                      if (["wounded"].includes(typeLower)) return "text-color-green-wounded";
                      if (["killed", "shotdown", "shot down", "destroyed"].includes(typeLower))
                        return "text-color-green-light";
                      if (["was wounded", "was damaged", "bailout"].includes(typeLower))
                        return "text-warning";
                      if (["was killed", "was shot down", "crashed"].includes(typeLower))
                        return "text-danger";
                      if (["disco"].includes(typeLower)) return "text-color-red";
                      return "text-gray";
                    }
                    console.warn("Unexpected entry.action_type value:", entry.action_type);
                    return "text-gray";
                  })();

                  let hits = "";
                  let damagePercentage = "N/A";

                  try {
                    const extraData = JSON.parse(entry.extra_data);
                    hits = extraData?.damage?.hits
                      ? Object.entries(extraData.damage.hits).map(([key, value]) => {
                          const mappedAmmo = mapAmmoType(key);
                          return (
                            <div key={key}>
                              {mappedAmmo}: {value}
                            </div>
                          );
                        })
                      : "";

                    damagePercentage = extraData?.damage?.pct
                      ? `${extraData.damage.pct}%`
                      : "";
                  } catch (error) {
                    console.error("Error parsing extra_data:", error);
                  }

                  function stringifyIfObject(value) {
                    if (typeof value === "string") return value;
                    if (typeof value === "object" && value !== null) {
                      const asString = JSON.stringify(value);
                      return asString === "{}" ? "" : asString;
                    }
                    return "";
                  }

                  const opposingObjectName = stringifyIfObject(entry.opposing_object_name);
                  const opposingPlayerName = stringifyIfObject(entry.opposing_player_name);

                  // Suppose the server also provides "entry.opposing_sortie_id" for that row
                  // We only make the row clickable if there's a real "opposingPlayerName"
                  // and an "opposing_sortie_id"
                  const isClickable =
                    opposingPlayerName.trim() !== "" && entry.opposing_sortie_id;

                  return (
                    <tr
                      key={index}
                      // Add onClick if isClickable is true
                      onClick={() => {
                        if (isClickable) {
                          handleOpposingSortieClick(entry.opposing_sortie_id);
                        }
                      }}
                      style={{ cursor: isClickable ? "pointer" : "default" }}
                    >
                      <td className="text-start">{formattedCombatLogDate(entry.date)}</td>
                      <td className={`text-start ${rowClass}`}>
                        <td className={`text-start ${rowClass}`}>
                          {damagePercentage !== "N/A" && damagePercentage
                            ? `${entry.action_type} - ${damagePercentage}`
                            : entry.action_type}
                        </td>
                      </td>
                      <td className="text-start">{opposingObjectName}</td>
                      <td className="text-start">{opposingPlayerName}</td>
                      <td className="text-start">{hits}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* Original Modal for the *current* selectedSortie */}
      <Modal
        show={show}
        onHide={onHide}
        title={`Combat Record: ${formattedCombatLogDate(selectedSortie.date_start)}`}
        body={modalBody}
        size="lg"
      />

      {/* NEW: A second CombatRecordModal for the opposingSortie, if we have it */}
      {opposingSortie && (
        <CombatRecordModal
          show={showOpposingModal}
          onHide={() => {
            setShowOpposingModal(false);
            setOpposingSortie(null);
          }}
          selectedSortie={opposingSortie}
        />
      )}
    </>
  );
};

CombatRecordModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedSortie: PropTypes.shape({
    date_start: PropTypes.string.isRequired,
    aircraft_type: PropTypes.string.isRequired,
    payload: PropTypes.string,
    aircraft_mods: PropTypes.arrayOf(PropTypes.string),
    fuel: PropTypes.number,
    aircraft_damage_percentage: PropTypes.number,
    formatted_flight_time: PropTypes.string,
    overall_status: PropTypes.string,
    promotion_points: PropTypes.number,
    ammo: PropTypes.string,
    air_kills: PropTypes.number,
    air_kill_assists: PropTypes.number,
    ground_kills: PropTypes.number,
    killboard_pve: PropTypes.string,
    killboard_pvp: PropTypes.string,
  }).isRequired,
};

export default CombatRecordModal;
