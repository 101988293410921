// src/components/PilotDetails/PilotDetails.jsx
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ActivePilot from "./ActivePilot";
import PlayersPilotList from "./PlayersPilotList";
import PilotAwards from "./PilotAwards";
import CombatRecord from "./CombatRecord";


export default function PilotDetails() {
  const { pilotName } = useParams(); // Extract the nickname from the URL
  const [searchParams] = useSearchParams(); // For query params like tour, country, persona_id
  const navigate = useNavigate();

  // Extract persona_id from the URL, if present
  const personaIdParam = searchParams.get("persona_id");
  const isAliveParam = searchParams.get("alive");
  const isAlive = isAliveParam === "true";

  const [pilotData, setPilotData] = useState(null);
  const [pilotList, setPilotList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sortiesData, setSortiesData] = useState([]);

  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("101");

  const [activeTab, setActiveTab] = useState("active"); // Initialize activeTab state

  const countryOptions = [
    { label: "USSR", value: "101" },
    { label: "Great Britain", value: "102" },
    { label: "United States", value: "103" },
    { label: "Germany", value: "201" },
  ];

// Utility function to escape special characters in the pilot name
function escapeRegex(string) {
  if (typeof string !== "string") {
      console.warn("Invalid input for escapeRegex:", string);
      return ""; // Return an empty string for invalid inputs
  }
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
}


  // 1) Fetch tours
  useEffect(() => {
    const fetchTours = async () => {
      try {
        const tourResponse = await axios.get("https://il2statsapi.combatbox.net/api/tour");
        const { current_tour, past_tours } = tourResponse.data;
        const combined = [current_tour, ...past_tours];
        setTours(combined);

        const paramTour = searchParams.get("tour");
        setSelectedTour(paramTour || String(current_tour.tour_id));

        const paramCountry = searchParams.get("country");
        setSelectedCountry(paramCountry || "101");
      } catch (err) {
        console.error("Error fetching tours:", err);
        setError("Error fetching tours data.");
      }
    };
    fetchTours();
  }, [searchParams]);

  // 2) Fetch pilot data based on persona_id or other parameters
  useEffect(() => {
    if (!pilotName || !selectedTour || !selectedCountry) return;

    const fetchPilotData = async () => {
      try {
        setLoading(true);
        setError("");

        // Escape the pilot name to ensure it's safe for usage in dynamic queries
        const sanitizedPilotName = encodeURIComponent(escapeRegex(pilotName));
        // FOR NOW WE ARE NOT USING THE SANITIZED NAME TO ALLOW ALL PLAYERS TO SEE THEIR INFO!
        // Construct the base URL
        let url = `https://il2statsapi.combatbox.net/api/vlifestats?tour=${selectedTour}&name=${pilotName}&country=${selectedCountry}&persona_id=${encodeURIComponent(personaIdParam)}&alive=${isAlive ? "true" : "false"}`;

        let urlPilotList = `https://il2statsapi.combatbox.net/api/vlifestats?tour=${selectedTour}&name=${pilotName}&country=${selectedCountry}&persona_id=${encodeURIComponent(personaIdParam)}`;
        const response = await axios.get(url);

        const PilotListResponse = await axios.get(urlPilotList);

        if (personaIdParam) {
          if (response.data && typeof response.data === "object") {
            setPilotData(response.data);
            setPilotList(PilotListResponse);
            console.log("Pilot data: ", response);
          } else {
            setError("No pilot data found for the specified persona_id.");
            setPilotData(null);
          }
        } else {
          if (response.data && Array.isArray(response.data.data)) {
            if (response.data.data.length > 0) {
              setPilotData(response.data.data[0]);
            } else {
              setError("No pilot data found for the selected tour and country.");
              setPilotData(null);
            }
          } else {
            setError("Invalid response format from the API.");
            setPilotData(null);
          }
        }
      } catch (err) {
        console.error("Error fetching pilot details:", err);
        setError("Error fetching pilot details.");
        setPilotData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchPilotData();
  }, [pilotName, selectedTour, selectedCountry, personaIdParam, isAlive]);



  // 3) Fetch sorties for this pilot based on persona_id
  useEffect(() => {


    // Ensure pilotData is an array and has at least one element
    if (!Array.isArray(pilotData) || pilotData.length === 0) return;

    // Find the persona that matches personaIdParam
    const selectedPersona = pilotData.find(
      (persona) => persona.persona_id === Number(personaIdParam) // Convert personaIdParam to a number
    );

    // If no matching persona is found, exit early
    if (!selectedPersona) {
      console.warn(`No persona found with persona_id: ${personaIdParam}`);
      return;
    }

    //Set the pilotdata to the correctly selected persona_id
    setPilotData(selectedPersona);

    const fetchSortiesData = async () => {
      try {
        const url = `https://il2statsapi.combatbox.net/api/sorties?persona=${selectedPersona.persona_id}`;
        const response = await axios.get(url);
        // console.log("Sorties API Response:", response);

        if (response.data) {
          setSortiesData(response.data);

        } else {
          setSortiesData([]);
        }
      } catch (err) {
        console.error("Error fetching sorties data:", err);
        setSortiesData([]);
      }
    };

    fetchSortiesData();
  }, [pilotData, personaIdParam]);

  const handlePilotSelect = async (pilot) => {
    setPilotData(pilot); // Update the active pilot

    // Fetch and set sorties data for the selected pilot
    try {
      const url = `https://il2statsapi.combatbox.net/api/sorties?persona=${pilot.persona_id}`;
      const response = await axios.get(url);
      console.log("Sorties API Response for Selected Pilot:", response);

      if (response.data) {
        setSortiesData(response.data);
      } else {
        setSortiesData([]);
      }
    } catch (err) {
      console.error("Error fetching sorties data for selected pilot:", err);
      setSortiesData([]);
    }
  };



  // Render error message if any
  if (error) {
    return (
      <div className="container">
        <p className="alert alert-warning">{error}</p>
      </div>
    );
  }

  // Render loading state
  if (loading) {
    return (
      <div className="container">
        <p>Loading pilot details...</p>
      </div>
    );
  }

  // Handlers for tour and country selection
  const handleTourChange = (tour) => {
    setSelectedTour(tour);
    // Optionally, update the URL query params
    const params = new URLSearchParams(searchParams);
    params.set("tour", tour);
    navigate({
      pathname: `/pilot/${encodeURIComponent(pilotName)}`,
      search: params.toString(),
    });
  };
  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    // Optionally, update the URL query params
    const params = new URLSearchParams(searchParams);
    params.set("country", country);
    navigate({
      pathname: `/pilot/${encodeURIComponent(pilotName)}`,
      search: params.toString(),
    });
  };

  return (
    <div className="container">
      {/* Header Row */}
      <div className="row row-col-auto justify-content-between py-4">
        <div className="col-md-5 col-12">
          <h1>{pilotData?.nickname || "Pilot Details"}</h1>
          <h2>
            {pilotData && typeof pilotData.squad_name === "string" && pilotData.squad_name.trim() !== ""
              ? pilotData.squad_name
              : ""}
          </h2>

        </div>
        <div className="col-md-2 col-12">
          <div className="mb-2">
            <label htmlFor="tourSelect">Tour:</label>
            <select
              id="tourSelect"
              className="form-select"
              value={selectedTour}
              onChange={(e) => handleTourChange(e.target.value)}
            >
              <option value="">-- Choose a Tour --</option>
              {tours.map((t) => (
                <option key={t.tour_id} value={t.tour_id}>
                  {t.tour_name || `Tour ${t.tour_id}`}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="countrySelect">Country:</label>
            <select
              id="countrySelect"
              className="form-select"
              value={selectedCountry}
              onChange={(e) => handleCountryChange(e.target.value)}
            >
              {countryOptions.map((c) => (
                <option key={c.value} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>


      {/* Tab Navigation */}
      <nav className="navbar">
        <ul className="navbar-nav pilot-details w-100 d-flex">
          {/* Profile Tab */}
          <li className={`nav-item ${activeTab === "active" ? "active" : ""}`}>
            <button
              className={`nav-link btn ${activeTab === "active" ? "active" : ""}`}
              onClick={() => setActiveTab("active")}
              disabled={activeTab === "active"}
            >
              <div className="flex-column d-flex">
                <div>Profile</div>
                <div className="text-small">
                  {activeTab === "active"
                    ? `${pilotData?.ShortRank || ""} ${pilotData?.PilotName || "Profile"}`
                    : ""}
                </div>
              </div>
            </button>
          </li>

          {/* Awards Tab */}
          <li className={`nav-item ${activeTab === "awards" ? "active" : ""}`}>
            <button
              className={`nav-link btn ${activeTab === "awards" ? "active" : ""}`}
              onClick={() => setActiveTab("awards")}
              disabled={activeTab === "awards"}
            >
              <div className="flex-column d-flex">
                <div>Awards</div>
                <div className="text-small">
                  {activeTab === "awards"
                    ? `${pilotData?.ShortRank || ""} ${pilotData?.PilotName || "Awards"}`
                    : ""}
                </div>
              </div>
            </button>
          </li>

          {/* Combat Record Tab */}
          <li className={`nav-item ${activeTab === "combatRecord" ? "active" : ""}`}>
            <button
              className={`nav-link btn ${activeTab === "combatRecord" ? "active" : ""}`}
              onClick={() => setActiveTab("combatRecord")}
              disabled={activeTab === "combatRecord"}
            >
              <div className="flex-column d-flex">
                <div>Combat Record</div>
                <div className="text-small">
                  {activeTab === "combatRecord"
                    ? `${pilotData?.ShortRank || ""} ${pilotData?.PilotName || "Combat Record"}`
                    : ""}
                </div>
              </div>
            </button>
          </li>

          {/* Spacer */}
          <div className="flex-grow-1"></div>

          {/* Pilot List Tab */}
          <li className={`nav-item ${activeTab === "pilotList" ? "active" : ""}`}>
            <button
              className={`nav-link pilot-list btn ${activeTab === "pilotList" ? "active" : ""}`}
              onClick={() => setActiveTab("pilotList")}
              disabled={activeTab === "pilotList"}
            >
              Pilot List
            </button>
          </li>
        </ul>
      </nav>


      {/* Main Content */}
      <div>
        {activeTab === "active" && (
          <ActivePilot
            pilotData={pilotData}
            sortiesData={sortiesData}
            isAlive={isAlive} // pass the boolean
          />
        )}
        {activeTab === "pilotList" && (
          <PlayersPilotList
            pilotList={pilotList}
            onPilotSelect={(pilot) => {
              handlePilotSelect(pilot); // Update active pilot
              setActiveTab("active"); // Switch to Profile tab
            }}
          />
        )}
        {activeTab === "awards" && <PilotAwards pilotData={pilotData} />}
        {activeTab === "combatRecord" && (
          <CombatRecord
            sortiesData={sortiesData}
            pilotData={pilotData}
            onPilotSelect={(pilot) => {
              handlePilotSelect(pilot);
            }}
          />
        )}

      </div>
    </div>
  );
}
