import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// faction should be "Allies" or "Axis"
function DisplayTopStreaks({ faction, campaignData }) {
  const [topPilots, setTopPilots] = useState([]);

  // Destructure your arrays
  const { PilotStreaksAllied = [], PilotStreaksAxis = [] } = campaignData;

  useEffect(() => {
    if (faction === "Allies") {
      const sortedAllies = [...PilotStreaksAllied].sort(
        (a, b) => (b.StreakBonus || 0) - (a.StreakBonus || 0)
      );
      setTopPilots(sortedAllies.slice(0, 5));
    } else {
      const sortedAxis = [...PilotStreaksAxis].sort(
        (a, b) => (b.StreakBonus || 0) - (a.StreakBonus || 0)
      );
      setTopPilots(sortedAxis.slice(0, 5));
    }


  }, [faction, PilotStreaksAllied, PilotStreaksAxis]);

  if (!topPilots.length) {
    return <p>No pilots found for {faction}.</p>;
  }

  return (
    <div className="container">
      <div className="text-center">

        <h4 className="text-uppercase fw-bolder text-center pt-3">
          Top 5 {faction} Pilots
        </h4>
      </div>
      <table
        className="table table-dark table-striped table-bordered"
        style={{ tableLayout: "fixed" }}
        title="base supply damage + bonus damage = new supply damage"
      >
        <thead>
          <tr>
            <th style={{ width: "12%" }}>Name</th>
            <th style={{ width: "15%" }}>Streak Bonus Damage</th>
            <th style={{ width: "7%" }}>Missions</th>
          </tr>
        </thead>
        <tbody>
          {topPilots.map((pilot, idx) => (
            <tr key={idx}>
              <td>{pilot.Name}</td>
              <td>
                {(() => {
                  const cappedBonus = Math.min(pilot.StreakBonus, 1.0);
                  const bonusPercent = (cappedBonus * 100).toFixed(0);
                  const totalPercent = ((cappedBonus + 1) * 100).toFixed(0);
                  return (
                    <>
                      100% + {bonusPercent}% = <strong>{totalPercent}%</strong>
                    </>
                  );
                })()}
              </td>
              <td>{pilot.SuccessfulMissionStreak || 0}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
}

DisplayTopStreaks.propTypes = {
  faction: PropTypes.string.isRequired,
  campaignData: PropTypes.shape({
    PilotStreaksAllied: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string,
        StreakBonus: PropTypes.number,
        SuccessfulMissionStreak: PropTypes.number
      })
    ),
    PilotStreaksAxis: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string,
        StreakBonus: PropTypes.number,
        SuccessfulMissionStreak: PropTypes.number
      })
    )
  }).isRequired
};

export default DisplayTopStreaks;
