import React from "react";
import PropTypes from "prop-types";
import "../styles/Header/header.css";

/**
 * Renders a calendar for the given year/month, highlights the `missionDay`,
 * shows events on each day, and allows clicks for more info.
 *
 * Also has header controls to navigate months/years:
 *  - prevYear, nextYear
 *  - prevMonth, nextMonth
 */
function MissionCalendar({
  year,
  month,
  missionDay,         // numeric day of the month to mark with "X"
  startDate,          // Date object: campaign start
  endDate,            // Date object: campaign end
  events,             // array of { date: Date, title: string }
  onDayClick,         // callback(date: Date, eventsForDay: object[])
  onChangeYear,       // callback(newYear: number, newMonth: number)
  onChangeMonth       // callback(newYear: number, newMonth: number)
}) {
  // For display
  const monthNames = [
    "January","February","March","April","May","June",
    "July","August","September","October","November","December"
  ];
  const currentMonthName = monthNames[month - 1];

  // Helper: days in the current month
  const daysInMonth = new Date(year, month, 0).getDate();
  // Weekday of 1st (0=Sunday, 1=Monday...)
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();

  // Utility: Is a given day between startDate & endDate?
  function isWithinCampaign(day) {
    if (!startDate || !endDate) return false;
    // We build a date object for this day
    const thisDate = new Date(year, month - 1, day, 12, 0, 0); 
    // Compare
    return thisDate >= startDate && thisDate <= endDate;
  }

  // Utility: events for a given day
  function getEventsForDay(day) {
    const thisDate = new Date(year, month - 1, day);
    return events.filter((evt) => {
      return (
        evt.date.getFullYear() === thisDate.getFullYear() &&
        evt.date.getMonth() === thisDate.getMonth() &&
        evt.date.getDate() === thisDate.getDate()
      );
    });
  }

  // Build calendar day cells
  const calendarCells = [];

  // (A) blank cells before the 1st
  for (let i = 0; i < firstDayOfMonth; i++) {
    calendarCells.push(<td key={`empty-start-${i}`} className="empty"></td>);
  }

  // (B) actual days
  for (let day = 1; day <= daysInMonth; day++) {
    const dayEvents = getEventsForDay(day);
    const isMission = day === missionDay;
    const inCampaignRange = isWithinCampaign(day);
  
    let tdClass = "text-center";
    if (isMission) tdClass += " mission-day";
    if (dayEvents.length > 0) tdClass += " has-event";
    if (inCampaignRange) tdClass += " in-campaign-range";
  
    // build a unique list of event titles, removing duplicates
    const allTitles = dayEvents.map((evt) => evt.title);
    const uniqueTitles = [...new Set(allTitles)];
  
    calendarCells.push(
      <td
        key={`day-${day}`}
        className={tdClass}
        style={{ cursor: "pointer" }}
        onClick={() => onDayClick?.(new Date(year, month - 1, day), dayEvents)}
      >
        <div>{isMission ? "X" : day}</div>
  
        {/* only show these lines if dayEvents.length > 0 */}
        {dayEvents.length > 0 && (
          <div className="event-label text-info small">
            {uniqueTitles.map((title, idx) => (
              <div key={idx}>{title}</div>
            ))}
          </div>
        )}
      </td>
    );
  }
  

  // (C) fill end of last week
  while (calendarCells.length % 7 !== 0) {
    calendarCells.push(
      <td
        key={`empty-end-${calendarCells.length}`}
        className="empty"
      ></td>
    );
  }

  // chunk into weeks
  const weeks = [];
  for (let i = 0; i < calendarCells.length; i += 7) {
    weeks.push(
      <tr key={`week-${i / 7}`}>{calendarCells.slice(i, i + 7)}</tr>
    );
  }

  // (1) Convert the campaign start/end to "month-level" Date objects
  // e.g. 1st of the startDate's month, 1st of the endDate's month
  const startOfStartMonth = new Date(
    startDate.getFullYear(), 
    startDate.getMonth(), 
    1
  );
  const startOfEndMonth = new Date(
    endDate.getFullYear(), 
    endDate.getMonth(), 
    1
  );

  // (2) Helper to see if we can go to the previous month
  function canGoPrevMonth() {
    // If we tried to go one month earlier
    const prevMonthCandidate = new Date(year, month - 2, 1); 
    // e.g. if month=9 => (year, 7, 1) => August
    return prevMonthCandidate >= startOfStartMonth;
  }

  // (3) Helper to see if we can go to the next month
  function canGoNextMonth() {
    // One month later
    const nextMonthCandidate = new Date(year, month, 1);
    // e.g. if month=9 => (year, 9, 1) => October
    return nextMonthCandidate <= startOfEndMonth;
  }

  // (4) The existing "handlePrevMonth" but now also checking in the parent.
  // We'll still call onChangeMonth(newYear, newMonth), 
  // but only if canGoPrevMonth() is true
  function handlePrevMonth() {
    if (!canGoPrevMonth()) return;
    let newMonth = month - 1;
    let newYear = year;
    if (newMonth < 1) {
      newMonth = 12;
      newYear = year - 1;
    }
    onChangeMonth?.(newYear, newMonth);
  }

  function handleNextMonth() {
    if (!canGoNextMonth()) return;
    let newMonth = month + 1;
    let newYear = year;
    if (newMonth > 12) {
      newMonth = 1;
      newYear = year + 1;
    }
    onChangeMonth?.(newYear, newMonth);
  }
  return (
    <div className="calendar-container">
      {/* Calendar Header with Year/Month controls */}
      <div className="calendar-header text-light text-center d-flex justify-content-between align-items-center">       
        {/* Month controls */}
        <button
          className="btn btn-sm btn-outline-light me-2"
          onClick={handlePrevMonth}
          disabled={!canGoPrevMonth()}  // <--- disable if false
        >
          &lt; Month
        </button>

        {/* Title: e.g. "September 1944" */}
        <span className="fw-bold mx-3">
          {currentMonthName} {year}
        </span>

        <button
          className="btn btn-sm btn-outline-light ms-2"
          onClick={handleNextMonth}
          disabled={!canGoNextMonth()}   // <--- disable if false
        >
          Month &gt;
        </button>
      </div>

      <table className="table table-dark table-bordered calendar text-light mt-2">
        <thead>
          <tr style={{width:"5%"}}>
            <th style={{width:"5%"}}>S</th>
            <th style={{width:"5%"}}>M</th>
            <th style={{width:"5%"}}>T</th>
            <th style={{width:"5%"}}>W</th>
            <th style={{width:"5%"}}>T</th>
            <th style={{width:"5%"}}>F</th>
            <th style={{width:"5%"}}>S</th>
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    </div>
  );
}

MissionCalendar.propTypes = {
  /** The year to display (1-based, e.g. 1944). */
  year: PropTypes.number.isRequired,
  /** The month to display (1-based, e.g. 9 = September). */
  month: PropTypes.number.isRequired,
  /** The day in month to mark with 'X' (the mission day). */
  missionDay: PropTypes.number,

  /** The campaign start date (for highlighting). */
  startDate: PropTypes.instanceOf(Date),
  /** The campaign end date (for highlighting). */
  endDate: PropTypes.instanceOf(Date),

  /** Array of events: each must have { date: Date, title: string }. */
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      title: PropTypes.string.isRequired
    })
  ),

  /** Callback when the user clicks a day. (date, eventsForDay) => void */
  onDayClick: PropTypes.func,

  /** Callback for changing the year: (newYear, currentMonth) => void */
  onChangeYear: PropTypes.func,
  /** Callback for changing the month: (newYear, newMonth) => void */
  onChangeMonth: PropTypes.func
};

MissionCalendar.defaultProps = {
  missionDay: null,
  startDate: null,
  endDate: null,
  events: [],
  onDayClick: null,
  onChangeYear: null,
  onChangeMonth: null
};

export default MissionCalendar;
