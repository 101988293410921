import React from "react";
import { useNavigate } from 'react-router-dom';
import DisplayPilotStreaksList from '../components/lists/DisplayPilotStreaksList';
import DisplayAirfieldsList from "../components/lists/DisplayAirfieldsList";
import DisplayObjectivesList from "../components/lists/DisplayObjectivesList";
import DisplayCampaignStatus from '../components/DisplayCampaignStatus';
import DisplayWarEffort from '../components/DisplayWarEffort';
import DisplayCampaignStoryEvents from '../components/DisplayCampaignStoryEvents';
import DisplayWeatherForecast from '../components/DisplayWeatherForecast';
import DisplaySquadStreaksList from "../components/lists/DisplaySquadStreakList";
import DisplayOnlinePlayers from '../components/DisplayOnlinePlayers';
import MapSlider from "../components/MapSlider";
import { useEffect, useState } from "react";
import axios from "axios";
import '../styles/Main/main.css';

function Main() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [tourMode, setTourMode] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTourMode = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://il2statsapi.combatbox.net/api/tour");

        if (response.data?.current_tour?.tour_mode) {
          setTourMode(response.data.current_tour.tour_name);
        } else {
          throw new Error("Tour mode not found in API response");
        }
      } catch (err) {
        console.error("Error fetching tour mode:", err);
        setError("Unable to determine tour mode.");
      } finally {
        setLoading(false);
      }
    };

    fetchTourMode();
  }, []);

  /// santitize tour NAME not TOUR MODE - fix later
  const sanitizedTourMode = () => {
    if (!tourMode) return "";
    // Split the string by spaces and get the last word
    const parts = tourMode.trim().split(" ");
    return parts[parts.length - 1];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sanitizedMode = sanitizedTourMode();
        const url = `https://m2.combatbox.net/${sanitizedMode}-campaign/${sanitizedMode}-campaign-latest.json.aspx`;


        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const jsonData = await response.json();
        console.log("Camapign Data", jsonData);
        setData(jsonData);
      } catch (err) {
        console.error("Error fetching campaign data:", err);
        setError(err.message);
      }
    };

    if (tourMode) {
      fetchData();
    }
  }, [tourMode]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading || !data) {
    return <div>Loading...</div>;
  }



  const sortAndSlicePilots = (pilots) => {
    const sortedPilots = [...pilots].sort((a, b) => b.SuccessfulMissionStreak - a.SuccessfulMissionStreak);
    return {
      topFive: sortedPilots.slice(0, 5),
      remaining: sortedPilots.slice(5),
    };
  };

  const alliedPilots = sortAndSlicePilots(data.PilotStreaksAllied);
  const axisPilots = sortAndSlicePilots(data.PilotStreaksAxis);

  const alliedAirfields = data.Airfields.filter(airfield => airfield.Coalition === "Allies");
  const axisAirfields = data.Airfields.filter(airfield => airfield.Coalition === "Axis");

  const alliedObjectives = data.Objectives.filter(objective => objective.Coalition === "Allies");
  const axisObjectives = data.Objectives.filter(objective => objective.Coalition === "Axis");


  // const { alliedSquads = [], axisSquads = [] } = topFiveSquads;


  return (
    <div className="main">

      <h2 className="text-uppercase fw-bolder text-center pt-3">
        Campaign Status
      </h2>

      <div className="container-fluid">
        <div className="pt-3">
          <div className="row gx-4">
            <div className="col-md-4 col-12 pb-3 order-xs-1 order-sm-1 order-md-2">
              <DisplayOnlinePlayers campaignData={data} />
            </div>

            <div className="col-md-4 col-12 pb-3 order-xs-2 order-sm-2 order-md-1">
              <DisplayPilotStreaksList
                campaignData={data}
                faction="Allies"
              />
            </div>


            <div className="col-md-4 col-12 pb-3 order-xs-3 order-sm-3 order-lg-3">
              <DisplayPilotStreaksList
                campaignData={data}
                faction="Axis"
              />
            </div>

          </div>


          <div className="row">
            {/* Mid Section */}
            <div className="col-lg-4 col-12 order-1 order-lg-2">
              <div className="row">
                <div className="pt-2">
                <h4 className="text-uppercase fw-bolder text-center pt-3">
        Map
      </h4>
                  <MapSlider maxDay={data.Day.DayInCampaign} showSlider={true} showAnimationControls={true} />
                </div>
              </div>

            </div>

            {/* Allied Section */}
            <div className="col-lg-4 col-12 order-2 order-lg-1">
              <h4 className="text-uppercase fw-bolder text-center pt-3">
                ALLIES Area of Operations
              </h4>
              <div className="text-uppercase text-start">Allied Owned Airfields</div>
              <DisplayAirfieldsList airfields={alliedAirfields} showActiveOnly={true} />

              <div className="text-uppercase text-start mt-3">Allied Held Objectives</div>
              <DisplayObjectivesList objectives={alliedObjectives} showActiveOnly={true} />

              <div className="text-start mt-3">
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    navigate(`/full-list/Allied`, {
                      state: { airfields: alliedAirfields, objectives: alliedObjectives, maxDay: data.Day.DayInCampaign },
                    })
                  }
                >
                  Show Full List
                </button>
              </div>
            </div>



            {/* Axis Section */}
            <div className="col-lg-4 col-12 order-3">
              <h4 className="text-uppercase fw-bolder text-center pt-3">
                AXIS area of OPerations
              </h4>
              <div className="text-uppercase text-start">Axis Owned Airfields</div>
              <DisplayAirfieldsList airfields={axisAirfields} showActiveOnly={true} />
              <div className="text-uppercase text-start mt-3">Axis Held Objectives</div>
              <DisplayObjectivesList objectives={axisObjectives} showActiveOnly={true} />


              <div className="text-start mt-3">
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    navigate(`/full-list/Axis`, {
                      state: { airfields: axisAirfields, objectives: axisObjectives, maxDay: data.Day.DayInCampaign },
                    })
                  }
                >
                  Show Full List
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-12 pb-3 order-xs-3 order-sm-3 order-lg-1">
              <DisplayCampaignStatus campaignData={data} />
            </div>

            <div className="col-lg-4 col-12 pb-3 order-xs-1 order-sm-1 order-lg-2">
              <DisplayWeatherForecast weather={data} />
            </div>

            <div className="col-lg-4 col-12 pb-3 order-xs-2 order-sm-2 order-lg-3">
              <DisplayWarEffort campaignData={data} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 pt-2 offset-lg-4 col-12">
              <DisplayCampaignStoryEvents campaignData={data} />
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}

export default Main;
