import React, { useState } from "react";

export default function PlayersPilotList({ pilotList, onPilotSelect }) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" }); // Sort configuration

  const pilots = Array.isArray(pilotList?.data) ? pilotList.data : [];

  if (pilots.length === 0) {
    return <p>No pilot records found.</p>;
  }

  // Sort pilots: alive pilots always come first
  let sortedPilots = [...pilots].sort((a, b) => {
    const aStatus = !a.is_captured && !a.is_dead;
    const bStatus = !b.is_captured && !b.is_dead;

    if (aStatus === bStatus) return 0; // If statuses are equal, proceed to column sorting
    return aStatus ? -1 : 1; // Place "alive and not captured" pilots first
  });

  // Apply column sorting if a key is selected
  if (sortConfig.key) {
    sortedPilots = sortedPilots.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue === bValue) return 0;
      return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
    });
  }

  const handleSort = (key) => {
    setSortConfig((prev) => {
      if (prev.key === key) {
        // Toggle direction if the same column is clicked again
        return {
          key,
          direction: prev.direction === "ascending" ? "descending" : "ascending",
        };
      }
      // Set a new key and default to ascending order
      return { key, direction: "ascending" };
    });
  };

  const getSortArrow = (headerKey) => {
    if (sortConfig.key === headerKey) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  const handlePilotClick = (pilot) => {
    if (typeof onPilotSelect === "function") {
      onPilotSelect(pilot);
    } else {
      console.error("onPilotSelect is not a function.");
    }
  };

  const countryMap = {
    101: 'Sov',
    102: 'Raf',
    103: 'Usa',
    201: 'Ger',
  };

  // Coalition icons
  const getCoalitionName = (coalPref) => {
    switch (coalPref) {
      case 102:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/RAF_Roundel.svg`} alt="RAF" style={{ width: '25px' }} />;
      case 103:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/USAAF_Roundel.svg`} alt="USAAF" style={{ width: '20px' }} />;
      case 201:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/Germany_Roundel.svg`} alt="Luftwaffe" style={{ width: '20px' }} />;
      case 101:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/USSR_Roundel.svg`} alt="Soviet" style={{ width: '22px' }} />;
      default:
        return 'Unknown';
    }
  };

  const getStatusIcon = (pilot) => {
    if (pilot.is_dead) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/skull.png`}
          alt="Dead"
          title="Dead"
          style={{ width: "20px" }}
        />
      );
    }
    if (pilot.is_captured) {
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/handcuffs.png`}
          alt="Captured"
          title="Captured"
          style={{ width: "23px" }}
        />
      );
    }

    const woundLevel = pilot.wound_level || 0;
    let heartIcon = "full_heart.png";
    if (woundLevel >= 1 && woundLevel <= 25) heartIcon = "75_heart.png";
    else if (woundLevel >= 26 && woundLevel <= 50) heartIcon = "50_heart.png";
    else if (woundLevel >= 51 && woundLevel <= 75) heartIcon = "25_heart.png";
    else if (woundLevel >= 76 && woundLevel <= 100) heartIcon = "empty_heart.png";

    return (
      <img
        src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/${heartIcon}`}
        alt="Wound Level"
        title={`Wound Level: ${woundLevel}`}
        style={{ width: "20px" }}
      />
    );
  };

  return (
    <div className={`pt-5 mb-5 border bg-USSR`}>
      <div className="container-fluid pilot-stats">
        <h3>Pilots List</h3>
        <div className="d-flex justify-content-center">
          <div className="table-responsive">
            <table
              className="table table-striped table-hover table-darker table-bordered"
              style={{ tableLayout: "fixed", width: "1270px" }}
            >
              <thead>
                <tr>
                  <th scope="col" style={{ width: "2.5%" }}></th>
                  <th scope="col" style={{ width: "12%" }}>Player</th>
                  <th scope="col" style={{ width: "12%" }}>Pilot</th>

                  <th scope="col" className="text-center" style={{ width: '5%' }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/faction.png`}

                      alt="Country"
                      title="Country"
                      style={{ width: '45px' }}
                    />
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("total_air_kills")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/air_kills.png`}
                      alt="Air Kills"
                      title="Air Kills"
                      style={{ width: '45px' }}
                    /> {getSortArrow("total_air_kills")}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("total_ground_kills")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/ground_kills.svg`}
                      alt="Ground Kills"
                      title="Ground Kills"
                      style={{ width: '50px' }}
                    /> {getSortArrow("total_ground_kills")}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("number_sorties_flown")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/takeoffs.svg`}
                      alt="Sorties"
                      title="Sorties"
                      style={{ width: '60px' }}
                    />  {getSortArrow("number_sorties_flown")}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("flight_hours")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/flight_time.svg`}
                      alt="Flight Time"
                      title="Flight Time"
                      style={{ width: '40px' }}
                    /> {getSortArrow("flight_hours")}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: "12%", cursor: "pointer" }}
                    onClick={() => handleSort("promotion_points")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/rank_up.png`}
                      alt="Promotion Points"
                      title="Promotion Points"
                      style={{ width: '32px' }}
                    /> {getSortArrow("promotion_points")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedPilots.map((pilot, idx) => {
                  const promotionPoints = pilot.promotion_points || 0; // Get promotion points for the current pilot
                  const progress = Math.min((promotionPoints / 100) * 100, 100); // Calculate progress percentage, capped at 100%

                  return (
                    <tr key={idx} onClick={() => handlePilotClick(pilot)} style={{ cursor: "pointer" }}>
                      <td className="text-center">{getStatusIcon(pilot)}</td>
                      <td>{pilot.nickname}</td>
                      <td>{`${pilot.ShortRank || ""} ${pilot.PilotName || ""}`}</td>
                      <td className="text-center">{getCoalitionName(pilot.country)}</td>
                      <td className="text-center">{pilot.total_air_kills}</td>
                      <td className="text-center">{pilot.total_ground_kills}</td>
                      <td className="text-center">{pilot.number_sorties_flown}</td>
                      <td className="text-center">{pilot.flight_hours}</td>
                      <td className="text-center" style={{ minWidth: "180px" }}>
                        {progress >= 0 ? (
                          <div className="d-flex flex-column align-items-center">
                            <div className="rank-text">{pilot.Rank}</div>
                            <div
                              className="progress-rank-bar progress position-relative"
                              style={{ height: "15px", width: "90%" }}
                            >
                              <span
                                className="position-absolute w-100 text-center"
                                style={{
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  fontSize: "12px",
                                  zIndex: 2,
                                }}
                              >
                                {promotionPoints}/100
                              </span>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: `${progress}%` }}
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <span>Demoted for friendly fire.</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
