import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import Modal from "../components/Modal";

function DisplayOnlinePlayers({ campaignData }) {
    const [onlinePlayers, setOnlinePlayers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);


    const [remainingTime, setRemainingTime] = useState('00:00:00');

    useEffect(() => {
        if (campaignData.EstimatedMissionEnd) {
            const missionEndTime = new Date(campaignData.EstimatedMissionEnd); // Parse ISO 8601 time

            const updateRemainingTime = () => {
                const now = new Date();
                let timeLeft = missionEndTime - now; // Time difference in milliseconds

                if (timeLeft <= 0) {
                    setRemainingTime('00:00:00'); // If mission has ended
                    return;
                }

                const hours = Math.floor(timeLeft / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                setRemainingTime(
                    `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                );
            };

            updateRemainingTime(); // Initial call to set time immediately
            const intervalId = setInterval(updateRemainingTime, 1000); // Update every second

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [campaignData.EstimatedMissionEnd]);

    useEffect(() => {
        const fetchOnlinePlayers = async () => {
            try {
                const response = await axios.get("https://il2statsapi.combatbox.net/api/onlineplayers");
                if (response.data && Array.isArray(response.data)) {
                    setOnlinePlayers(response.data);
                } else {
                    throw new Error("Invalid API response format");
                }
            } catch (err) {
                console.error("Error fetching online players:", err);
                setError("Unable to fetch online player data.");
            } finally {
                setLoading(false);
            }
        };

        fetchOnlinePlayers();
    }, []);

    if (loading) return <div>Loading player data...</div>;
    if (error) return <div>Error: {error}</div>;

    const allies = onlinePlayers.filter(player => player.coalition === 1);
    const axis = onlinePlayers.filter(player => player.coalition === 2);

    const totalPlayers = allies.length + axis.length;


    // The values we use to display in the bar
    let visualAlliesPercentage = 0;
    let visualAxisPercentage = 0;

    // The values we display in the text
    let labelAlliesPercentage = 0;
    let labelAxisPercentage = 0;

    // If total players == 0, visually fill the bar 50/50,
    // but display "0%" in the text.
    if (totalPlayers === 0) {
        visualAlliesPercentage = 50;
        visualAxisPercentage = 50;
        labelAlliesPercentage = 0;
        labelAxisPercentage = 0;
    } else {
        const alliesCalc = (allies.length / totalPlayers) * 100;
        const axisCalc = (axis.length / totalPlayers) * 100;

        // Round or fix as needed
        visualAlliesPercentage = Math.round(alliesCalc);
        visualAxisPercentage = Math.round(axisCalc);

        // Also for the label, you can round or fix differently if you prefer
        labelAlliesPercentage = Math.round(alliesCalc);
        labelAxisPercentage = Math.round(axisCalc);
    }

    // Create modal body, etc...
    const modalBody = (
        <div className="row">
            <div className="col-md-6">
                <h5 className="text-center">Allies</h5>
                <table className="table table-bordered table-striped table-dark">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Time on Mission</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allies.map((player, index) => (
                            <tr key={index}>
                                <td>{player.nickname}</td>
                                <td>{player.timeOnMission}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
                <h5 className="text-center">Axis</h5>
                <table className="table table-bordered table-striped table-dark">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Time on Mission</th>
                        </tr>
                    </thead>
                    <tbody>
                        {axis.map((player, index) => (
                            <tr key={index}>
                                <td>{player.nickname}</td>
                                <td>{player.timeOnMission}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="d-flex justify-content-center">
            <div className="container-online-players">
                <h4 className="text-uppercase fw-bolder text-center pt-3">Online Players</h4>
                <table
                    className="table table-striped table-dark table-bordered text-center"
                    style={{ cursor: "pointer", tableLayout: "fixed" }}
                    onClick={openModal}
                >
                    <thead>
                        <tr>
                            <th>Allies</th>
                            <th>Axis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <h3>{allies.length}</h3>
                            </td>
                            <td>
                                <h3>{axis.length}</h3>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="progress progress-bar-dark" style={{ height: "20px" }}>
                                    <div
                                        className="progress-bar bg-danger text-end pe-3"
                                        role="progressbar"
                                        style={{ width: `${visualAlliesPercentage}%` }}
                                        aria-valuenow={visualAlliesPercentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        {labelAlliesPercentage}% Allies
                                    </div>
                                    <div
                                        className="progress-bar bg-primary text-start ps-3"
                                        role="progressbar"
                                        style={{ width: `${visualAxisPercentage}%` }}
                                        aria-valuenow={visualAxisPercentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        Axis {labelAxisPercentage}%
                                    </div>
                                </div>
                            </td>
                        </tr>
                            <tr>
                            <td className="bg-transparent text-white fs-3 fw-bold" colSpan={2}>{remainingTime}</td>
                            </tr>
                    </tbody>
                </table>

                <Modal
                    show={showModal}
                    onHide={closeModal}
                    title="Online Players Details"
                    body={modalBody}
                    size="lg"
                />
            </div>
        </div>
    );
}



DisplayOnlinePlayers.propTypes = {
    campaignData: PropTypes.shape({
        CampaignDayMissionStartTime: PropTypes.number.isRequired,
        // MissionEndTimeUTC: PropTypes.string.isRequired,
        Day: PropTypes.shape({
            Day: PropTypes.number.isRequired,
            Month: PropTypes.number.isRequired,
            Year: PropTypes.number.isRequired,
            DayInCampaign: PropTypes.number.isRequired,
        }).isRequired,
        DaysRemaining: PropTypes.number.isRequired,
    }).isRequired,
};

export default DisplayOnlinePlayers;
