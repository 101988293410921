import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DisplayAirfieldsList from '../../components/lists/DisplayAirfieldsList';
import DisplayObjectivesList from '../../components/lists/DisplayObjectivesList';

function AllAirfieldsAndObjectivesList() {
  const { state } = useLocation(); // Access state from navigate
  const { coalition } = useParams();

  // Default to empty arrays if state is undefined
  const airfields = state?.airfields || [];
  const objectives = state?.objectives || [];

  return (
    <div className="container main">
      <h2 className="text-uppercase fw-bolder text-center pt-3">
        All owned airfields and objectives for {coalition} 
      </h2>
      <div className='row'>
        <div className="mt-4">
          <div className="text-uppercase fw-bolder ps-1">Airfields</div>
          <DisplayAirfieldsList airfields={airfields} />
        </div>
        <div className="mt-4">
          <div className="text-uppercase fw-bolder ps-1">Objectives</div>
          <DisplayObjectivesList objectives={objectives} />
        </div>
      </div>
    </div>
  );
}

export default AllAirfieldsAndObjectivesList;
