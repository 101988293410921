import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/Header/header.css"; 

function HeaderImage() {
  const [tourName, setTourName] = useState("Apollo Beta 18 \"Big Normandy\"");
  const [error, setError] = useState(null);

  // State to track if viewport >= 768px
  const [isDesktop, setIsDesktop] = useState(() => {
    return typeof window !== "undefined" ? window.innerWidth >= 768 : true;
  });

  useEffect(() => {
    // Handler to update isDesktop on resize
    function handleResize() {
      setIsDesktop(window.innerWidth >= 768);
    }
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 1) Array of YouTube video IDs for desktop
  const youtubeVideoIds = [
     "-bK8E03gdjY",
     "tmQSRW1Ing0",
     "1KuhLRuHaFw",
  ];
  const randomYoutubeId =
    youtubeVideoIds[Math.floor(Math.random() * youtubeVideoIds.length)];
  const embedUrl = `https://www.youtube-nocookie.com/embed/${randomYoutubeId}?autoplay=1&mute=1&controls=0&loop=1&playlist=${randomYoutubeId}&rel=0&modestbranding=1`;

  // 2) Array of images for mobile
  const mobileImages = [
    "CombatBox_MUSTANG_modROEN_version_2.png",
    "CombatBox_No410_SQN_RCAF_-_Riksen.png",
    "CombatBox_TEMPEST_modROEN.png",
    "CombatBox-RoachFinal.jpg",
    "head_1.a5efc5804214.jpg",
    "image3A8809_Glitch2.jpg",
  ];
  const randomImage = mobileImages[Math.floor(Math.random() * mobileImages.length)];

  // Fetch the tour name
  useEffect(() => {
    const fetchTourName = async () => {
      try {
        const response = await axios.get("https://il2statsapi.combatbox.net/api/tour");
        if (response.data?.current_tour?.tour_name) {
          setTourName(response.data.current_tour.tour_name);
        } else {
          throw new Error("Tour name not found in the API response");
        }
      } catch (err) {
        console.error("Error fetching tour name:", err);
        setError("Unable to fetch tour name");
      }
    };
    fetchTourName();
  }, []);

  return (
    <div className="header-container">
      {/* Render the video if desktop, otherwise the random image */}
      {isDesktop ? (
        <div className="video-background">
          <iframe
            className="header-video"
            src={embedUrl}
            title="Random Combat Box Video"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}/images/header-images/${randomImage}`}
          alt="Combat Box"
          className="header-image"
        />
      )}

      {/* Overlayed text (always shown) */}
      <div className="header-text">
        <h1>Combat Box</h1>
        <h2>{error ? error : tourName}</h2>
      </div>
    </div>
  );
}

export default HeaderImage;
