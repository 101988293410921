import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import Navbar from './components/Navbar';
import Main from './pages/Main';
import Currated_Main from './pages/Currated_Main';
import ActivePilots from './pages/ActivePilots';
import MemorialWall from './pages/MemorialWall';
import ServerRules from './pages/ServerInfo/ServerRules';
import ServerSRS from './pages/ServerInfo/ServerSRS';
import ServerSupport from './pages/ServerInfo/ServerSupport';
import ServerCB_Radio from './pages/ServerInfo/ServerCB_Radio';
import PlaneList from './pages/AvailablePlaneList';
import Discord from './pages/Discord';
import Donate from './pages/Donate';
import Header from './components/Header';
import AirfieldDetails from './pages/DetailPages/AirfieldDetails';
import ObjectiveDetails from './pages/DetailPages/ObjectiveDetails';
import AllAirfieldsAndObjectivesList from './pages/DetailPages/AllAirfeildsAndObjectivesList';
import { CampaignProvider } from './contexts/CampaignContext';
import PilotDetails from './components/PilotDetails/PilotDetails';

function App() {
  const [tourMode, setTourMode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTourMode = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://il2statsapi.combatbox.net/api/tour");
        if (response.data?.current_tour?.tour_mode) {
          setTourMode(response.data.current_tour.tour_mode);
        } else {
          throw new Error("Tour mode not found in API response");
        }
      } catch (err) {
        console.error("Error fetching tour mode:", err);
        setError("Unable to determine tour mode.");
      } finally {
        setLoading(false);
      }
    };

    fetchTourMode();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Optional: Replace with a spinner
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <CampaignProvider>
      <Router>
        <Header />
        <Navbar />
        <div className="content">
          <Routes>
            {/* Redirect the root to the appropriate main page */}
            <Route
              path="/"
              element={tourMode === "classic" ? <Currated_Main /> : <Main />}
            />
            <Route path="/airfield/:name" element={<AirfieldDetails />} />
            <Route path="/objective/:name" element={<ObjectiveDetails />} />
            <Route
              path="/full-list/:coalition"
              element={<AllAirfieldsAndObjectivesList />}
            />
            <Route path="/pilots/active" element={<ActivePilots />} />
            <Route path="/pilots/memorial" element={<MemorialWall />} />
            <Route path="/pilot/:pilotName/*" element={<PilotDetails />} />
            <Route path="/server-info/rules" element={<ServerRules />} />
            <Route path="/server-info/srs" element={<ServerSRS />} />
            <Route path="/server-info/cb-radio" element={<ServerCB_Radio />} />
            <Route path="/server-info/support" element={<ServerSupport />} />
            <Route path="/plane-list" element={<PlaneList />} />
            <Route path="/discord" element={<Discord />} />
            <Route path="/donate" element={<Donate />} />
          </Routes>
        </div>
      </Router>
    </CampaignProvider>
  );
}

export default App;
