// src/components/PilotDetails/helpers/sanitizeMissionReport.js
export function sanitizeMissionReport(fullData, pilotData) {
    if (!fullData || fullData.Result === "CANCELLED") {
        return null;
    }

    const formatTimestamp = (startDate) => {
        if (!startDate) return "";
    
        // Parse the date and time parts
        const [datePart, timePart] = startDate.split("T");
        const [year, month, day] = datePart.split("-");
        const [hours, minutes] = timePart.split(":");
    
        // Map month numbers to month names
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const monthName = monthNames[parseInt(month, 10) - 1];
    
        // Format the final output
        return `${parseInt(day)} ${monthName} ${year} ${hours}${minutes} hours`;
    };
      

    const paragraphs = fullData.SortieAarParagraphs;
    if (!paragraphs || !Array.isArray(paragraphs)) {
        console.warn("No SortieAarParagraphs array found in:", fullData);
        return null;
    }

    let data = paragraphs.join("\n");

    // Replace pilot name
    data = data.replace(new RegExp(pilotData.nickname, "g"), pilotData.PilotName);

    // Replace "Z"
    data = data.replace(/\b(\d+)Z\b/g, "$1 hours");

    // Add a line break after every three periods
    data = data.replace(/\.{3}/g, "...\n");

    // Split back into lines
    let bodyData = data.split("\n");

    // Optionally group lines
    const groupedBodyData = [];
    for (let i = 0; i < bodyData.length; i += 2) {
        const combined = [bodyData[i], bodyData[i + 1]].filter(Boolean).join(" ");
        groupedBodyData.push(combined);
    }

    const missionTitle = formatTimestamp(fullData.SortieHistoricalStartTime) || "Unknown Start Time";
    const missionClassification = fullData.SortieAarClassification;

    // Example classification logic:
    const isClassified = missionClassification ?.toUpperCase().includes("SECRET");

    const missionResult = (() => {
        switch (fullData.Result) {
            case "LANDED":
                return "Landed";
            case "IN_FLIGHT":
                return "In Flight";
            case "BAILED_OUT":
                return "Bailed Out";
            default:
                return "Unknown";
        }
    })();

    return {
        title: missionTitle,
        titleClassification: missionClassification,
        footerResult: missionResult,
        body: groupedBodyData,
        isClassified,
    };
}