import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a red/blue stacked bar comparing two numeric values (Allied vs Axis).
 * If `reverseOrder` is true, Allies and Axis labels/values are swapped.
 */
function StackedBar({ label, alliedValue, axisValue, reverseOrder }) {
  // Compute total
  const total = alliedValue + axisValue;
  let leftPercent = 0;
  let rightPercent = 0;

  // Decide which numeric values go on the "left" (red) vs "right" (blue)
  // If reverseOrder is false => left=Allies, right=Axis
  // If reverseOrder is true  => left=Allies but uses the axisValue, right=Axis but uses alliedValue
  let leftValue, rightValue;
  let leftLabel, rightLabel;

  if (!reverseOrder) {
    // Normal mode
    leftValue = alliedValue;
    rightValue = axisValue;
    leftLabel = "Allies";
    rightLabel = "Axis";
  } else {
    // Reversed mode: Allies gets the Axis number, Axis gets the Allies number
    leftValue = axisValue;
    rightValue = alliedValue;
    leftLabel = "Allies";
    rightLabel = "Axis";
  }

  if (total > 0) {
    leftPercent = (leftValue / total) * 100;
    rightPercent = (rightValue / total) * 100;
  }

  return (
    <div className="mb-3">
      {/* Label */}
      <h6 className="text-white mb-1">{label}</h6>
      {/* Stacked bar */}
      <div className="progress" style={{ height: "20px" }}>
        {/* LEFT BAR = Allies (red) */}
        <div
          className="progress-bar bg-danger text-end px-2"
          role="progressbar"
          style={{ width: `${leftPercent}%` }}
          aria-valuenow={leftPercent}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {/* Show Allies + value if there's enough space */}
          {leftPercent > 10 && (
            <div className="d-inline-flex justify-content-end">
              <div className="pe-1">{leftLabel}</div>
              <strong>{leftValue}</strong>
            </div>
          )}
        </div>
        {/* RIGHT BAR = Axis (blue) */}
        <div
          className="progress-bar bg-primary text-start px-2"
          role="progressbar"
          style={{ width: `${rightPercent}%` }}
          aria-valuenow={rightPercent}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {/* Show Axis + value if there's enough space */}
          {rightPercent > 10 && (
            <div className="d-inline-flex justify-content-start">
              <strong>{rightValue}</strong>
              <div className="ps-1">{rightLabel}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

StackedBar.propTypes = {
  label: PropTypes.string.isRequired,
  alliedValue: PropTypes.number.isRequired,
  axisValue: PropTypes.number.isRequired,
  // New prop to flip labels/values
  reverseOrder: PropTypes.bool
};

StackedBar.defaultProps = {
  reverseOrder: false
};

export default StackedBar;
