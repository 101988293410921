import React from "react";
import PropTypes from "prop-types";
import StackedBar from "./StackedBar";

function DisplayWarEffort({ campaignData }) {
  return (
    <div className="war-effort-container">
        <h4 className="text-uppercase fw-bolder text-center pt-3">
        War effort
      </h4>
      <div className="border p-2 text-white">

        {/* 1) Normal bar (no reversal) */}
        <StackedBar
          label="Controlled Objectives"
          alliedValue={campaignData.AlliedControlledPointsToday}
          axisValue={campaignData.AxisControlledPointsToday}
          reverseOrder={false}
        />

        {/* 2) Normal bar (no reversal) */}
        <StackedBar
          label="Available Supply"
          alliedValue={campaignData.AlliedSupplyToday}
          axisValue={campaignData.AxisSupplyToday}
          reverseOrder={false}
        />

        {/* 3) Reversed bar (Allies # becomes Axis text, etc.) */}
        <StackedBar
          label="Enemy Airframes Destroyed"
          alliedValue={campaignData.LossesAllied.Aircraft}
          axisValue={campaignData.LossesAxis.Aircraft}
          reverseOrder={true}
        />

        <StackedBar
          label="Enemy Pilots Killed"
          alliedValue={campaignData.LossesAllied.Pilots}
          axisValue={campaignData.LossesAxis.Pilots}
          reverseOrder={true}
        />

        <StackedBar
          label="Enemy Vehicles Destroyed"
          alliedValue={campaignData.LossesAllied.Vehicles}
          axisValue={campaignData.LossesAxis.Vehicles}
          reverseOrder={true}
        />

        <StackedBar
          label="Enemy Buildings Destroyed"
          alliedValue={campaignData.LossesAllied.Buildings}
          axisValue={campaignData.LossesAxis.Buildings}
          reverseOrder={true}
        />

        <StackedBar
          label="Enemy Ships Sunk"
          alliedValue={campaignData.LossesAllied.Ships}
          axisValue={campaignData.LossesAxis.Ships}
          reverseOrder={true}
        />
      </div>
    </div>
  );
}

DisplayWarEffort.propTypes = {
  campaignData: PropTypes.shape({
    AlliedControlledPointsToday: PropTypes.number.isRequired,
    AxisControlledPointsToday: PropTypes.number.isRequired,
    AlliedSupplyToday: PropTypes.number.isRequired,
    AxisSupplyToday: PropTypes.number.isRequired,
    LossesAllied: PropTypes.shape({
      Aircraft: PropTypes.number,
      Pilots: PropTypes.number,
      Vehicles: PropTypes.number,
      Buildings: PropTypes.number,
      Ships: PropTypes.number
    }).isRequired,
    LossesAxis: PropTypes.shape({
      Aircraft: PropTypes.number,
      Pilots: PropTypes.number,
      Vehicles: PropTypes.number,
      Buildings: PropTypes.number,
      Ships: PropTypes.number
    }).isRequired
  }).isRequired
};

export default DisplayWarEffort;
