import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MissionCalendar from "../components/MissionCalendar";
// Import your plane JSON
import planeData from "../assets/Apollo_Aircraft_Availablity.json";
// Import your custom Bootstrap-based modal
import Modal from "../components/Modal";
import "../styles/Header/header.css";

function DisplayCampaignStatus({ campaignData }) {
  const [remainingTime, setRemainingTime] = useState("00:00:00");

  // ---------------------
  // 1) Local state for YEAR & MONTH
  // ---------------------
  const [calendarYear, setCalendarYear] = useState(campaignData.Day.Year);
  const [calendarMonth, setCalendarMonth] = useState(campaignData.Day.Month);

  // 2) State for the Modal
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState(null);

  // Countdown to mission end (unchanged)
  useEffect(() => {
    if (campaignData.EstimatedMissionEnd) {
      const missionEndTime = new Date(campaignData.EstimatedMissionEnd);

      const updateRemainingTime = () => {
        const now = new Date();
        let timeLeft = missionEndTime - now;

        if (timeLeft <= 0) {
          setRemainingTime("00:00:00");
          return;
        }

        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setRemainingTime(
          `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
          )}:${String(seconds).padStart(2, "0")}`
        );
      };

      updateRemainingTime();
      const intervalId = setInterval(updateRemainingTime, 1000);
      return () => clearInterval(intervalId);
    }
  }, [campaignData.EstimatedMissionEnd]);

  // Format "Day-MONTH-Year"
  const formatDate = () => {
    const day = String(campaignData.Day.Day).padStart(2, "0");
    const monthNames = [
      "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
    const month = monthNames[campaignData.Day.Month - 1];
    const year = campaignData.Day.Year;
    return `${day}-${month}-${year}`;
  };

  // Current campaign date
  const currentDate = new Date(
    campaignData.Day.Year,
    campaignData.Day.Month - 1,
    campaignData.Day.Day
  );

  // Start date => subtract (DayInCampaign - 1)
  const campaignStart = new Date(currentDate);
  campaignStart.setDate(
    campaignStart.getDate() - (campaignData.Day.DayInCampaign - 1)
  );

  // End date => add DaysRemaining
  const campaignEnd = new Date(campaignStart);
  campaignEnd.setDate(campaignEnd.getDate() + campaignData.DaysRemaining);

  // ---------------------
  // 3) Build "plane availability" events from planeData
  // ---------------------
  // Each object in planeData has "Min Date" and "Airframe" and more
  // We'll store the entire plane object in the "event" so we can show more info in the modal.
  const planeEvents = planeData.map((p) => ({
    date: new Date(p["Min Date"]),
    // We store the entire plane data for later usage
    plane: p,
    title: p["Airframe"]
  }));

  // Existing campaign events: start, mission, end
  const campaignEvents = [
    { date: campaignStart, title: "Campaign Start" },
    { date: currentDate, title: "Today's Mission" },
    { date: campaignEnd, title: "Campaign End" }
  ];

  // Merge them all
  const allEvents = [...campaignEvents, ...planeEvents];

  // Only show "X" if we are on the actual current month/year
  let displayedMissionDay = null;
  if (calendarYear === campaignData.Day.Year && calendarMonth === campaignData.Day.Month) {
    displayedMissionDay = campaignData.Day.Day;
  }

  // If you never want to change year, ignore handleChangeYear
  function handleChangeYear(newYear, currentMonth) {
    console.log("Change year =>", newYear, currentMonth);
    // do nothing or clamp
  }

  // For month changes, update local state
  function handleChangeMonth(newYear, newMonth) {
    console.log("Change month =>", newYear, newMonth);
    setCalendarYear(newYear);
    setCalendarMonth(newMonth);
  }

  const countryMap = {
    US: "USAAF",
    GB: "RAF",
    DE: "Luftwaffe",
    USSR: "Soviet"
  };

  // 4) onDayClick => open modal if there's an event
  function handleDayClick(date, dayEvents) {
    console.log("User clicked date:", date.toDateString(), "Events:", dayEvents);

    if (!dayEvents || dayEvents.length === 0) return;

    // If there's more than one plane or event, you could combine them in the body
    // For example, we build a small JSX list of them:
    const bodyContent = (
      <div>
        {
          dayEvents.map((evt, idx) => {
            if (evt.plane) {
              const p = evt.plane;
              // 2) Map the country code from p["Country"]
              const mappedCountry = countryMap[p["Country"]] || p["Country"];

              return (
                <div key={idx} className="mb-2 pt-2 ps-2 pe-2 border">
                  <h5 className="text-info">{p["Airframe"]}</h5>
                  <table className="table table-sm table-borderless text-white table-dark"
                    style={{ tableLayout: 'fixed' }}
                  >
                    <tbody>
                      <tr>
                        <th style={{ width: '30%' }}>Country</th>
                        {/* 3) Use mappedCountry instead of p["Country"] */}
                        <td style={{ width: '70%' }}>{mappedCountry}</td>
                      </tr>
                      <tr>
                        <th style={{ width: '30%' }}>Available Mods</th>
                        <td style={{ width: '70%' }}>{p["INCLUDE"]}</td>
                      </tr>
                      <tr>
                        <th style={{ width: '30%' }}>Restrictions</th>
                        <td style={{ width: '70%' }}>{p["EXCLUDE"]}</td>
                      </tr>
                      <tr>
                        <th style={{ width: '30%' }}>Minimum Supply</th>
                        <td style={{ width: '70%' }}>{p["Min Supply"]}</td>
                      </tr>
                      <tr>
                        <th style={{ width: '30%' }}>Required Runway Length</th>
                        <td style={{ width: '70%' }}>{p["Min Runway Length"]}</td>
                      </tr>
                      <tr>
                        <th style={{ width: '30%' }}>Min Distance to Front Line</th>
                        <td style={{ width: '70%' }}>{p["Min Distance To Front Line"]}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            } else {
              // It's a regular campaign event
              return (
                <div key={idx} style={{ marginBottom: "1rem" }}>
                  <h5>{evt.title}</h5>
                  <p className="text-white">No additional info.</p>
                </div>
              );
            }
          })}
      </div>

    );



    setModalTitle(`Events for ${date.toDateString()}`);


    setModalBody(bodyContent);
    setShowModal(true);
  }

  // 5) close modal
  function closeModal() {
    setShowModal(false);
    setModalTitle("");
    setModalBody(null);
  }

  return (
    <div className="campaign-status-container">
       <h4 className="text-uppercase fw-bolder text-center pt-3">
        Calendar
      </h4>
      <div className="border p-2">
        <div className="row">
          {/* Left stats table */}
          <div className="col-12">
            <table className="table table-sm table-responsive table-borderless">
              <tbody>
                <tr>
                  <th
                    className="bg-transparent text-white campaign-status-title-column"
                    scope="row"
                  >
                    Campaign Day
                  </th>
                  <td className="bg-transparent text-white airfield-details-icon-column">
                    {campaignData.Day.DayInCampaign}
                  </td>
                </tr>
                <tr>
                  <th
                    className="bg-transparent text-white campaign-status-title-column"
                    scope="row"
                  >
                    Campaign Date
                  </th>
                  <td className="bg-transparent text-white airfield-details-icon-column">
                    {formatDate()}
                  </td>
                </tr>
                <tr>
                  <th
                    className="bg-transparent text-white campaign-status-title-column"
                    scope="row"
                  >
                    Mission Start Time
                  </th>
                  <td className="bg-transparent text-white airfield-details-icon-column">
                    {campaignData.CampaignDayMissionStartTime}
                  </td>
                </tr>
                <tr>
                  <th
                    className="bg-transparent text-white campaign-status-title-column"
                    scope="row"
                  >
                    Days Remaining
                  </th>
                  <td className="bg-transparent text-white airfield-details-icon-column">
                    {campaignData.DaysRemaining}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Right side: the calendar */}
          <div className="col-lg-12 col-12">
            <MissionCalendar
              // Show "X" only if on the current campaign's month/year
              missionDay={displayedMissionDay}
              year={calendarYear}
              month={calendarMonth}
              startDate={campaignStart}
              endDate={campaignEnd}
              events={allEvents}
              onDayClick={handleDayClick}
              onChangeYear={handleChangeYear}
              onChangeMonth={handleChangeMonth}
            />
          </div>
        </div>
      </div>

      {/* 6) Our custom Bootstrap-based modal */}
      <Modal
        show={showModal}
        onHide={closeModal}
        title={modalTitle || "Event Details"}
        body={modalBody || <p>No event details.</p>}
        size="lg"
      />
    </div>
  );
}

DisplayCampaignStatus.propTypes = {
  campaignData: PropTypes.shape({
    CampaignDayMissionStartTime: PropTypes.number.isRequired,
    Day: PropTypes.shape({
      Day: PropTypes.number.isRequired,
      Month: PropTypes.number.isRequired,
      Year: PropTypes.number.isRequired,
      DayInCampaign: PropTypes.number.isRequired
    }).isRequired,
    DaysRemaining: PropTypes.number.isRequired,
    EstimatedMissionEnd: PropTypes.string
  }).isRequired
};

export default DisplayCampaignStatus;
