import React from 'react';

function ServerSupport() {
  return (
    <div>
      <h2 className="text-uppercase fw-bolder text-center pt-3">
        Support
      </h2>
      <div className='container-fluid'>
        <div className='col-12'>

          <h2>How to Get Support</h2>
          <p>
            Use the #support channel on our Discord to report bugs, get help, or create a ticket for rule violations such as chute killing, deliberate team kills, or offensive language. Creating a ticket allows you to report privately.
          </p>

          <h2>Network Issues</h2>
          <p>
            Our server is located in New York, with thousands of monthly pilots from around the world. Optimize your connection or check MTU settings if experiencing ping issues. The maximum ping is 350ms round-trip.
          </p>

          <h2>Server Administration</h2>
          <p>Administrators: Alonzo, Barnacles, Haluter, RufusK, Sketch, Talon, Wim</p>
          <p>
            The server is supported by our Patrons. Thanks to all contributors, past and present. Join our Discord community at: <a href="https://discord.combatbox.net">discord.combatbox.net</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServerSupport;
