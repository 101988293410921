import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Header/header.css';

function PilotStatsList({ alive = true }) {
  const navigate = useNavigate();

  // Tours + pilot stats
  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState('');
  const [pilotStats, setPilotStats] = useState([]);
  const [loadingTours, setLoadingTours] = useState(true);
  const [loadingPilots, setLoadingPilots] = useState(false);
  const [error, setError] = useState(null);

  // Searching + pagination
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25;

  // For rank config (if needed)
  const [rankConfig, setRankConfig] = useState(null);

  // Determine default sort based on whether these are alive or dead
  const defaultSortConfig = alive
    ? { key: 'promotion_points', direction: 'descending' }
    : { key: 'promotion_points', direction: 'descending' };

  const [sortConfig, setSortConfig] = useState(defaultSortConfig);

  // Field map for user-friendly table headers => API fields
  const sortByMap = {
    'Player': 'nickname',
    'Active Pilot': null,
    'Country': null,
    'Air Kills': 'total_air_kills',
    'Ground Kills': 'total_ground_kills',
    'Sorties': 'number_sorties_flown',
    'Flight Time': 'flight_hours',
    'Promotion Points': 'promotion_points',
    'Status': null,
  };


  const countryMap = {
    101: 'Sov',
    102: 'Raf',
    103: 'Usa',
    201: 'Ger',
  };

  // Coalition icons
  const getCoalitionName = (coalPref) => {
    switch (coalPref) {
      case 102:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/RAF_Roundel.svg`} alt="RAF" style={{ width: '25px' }} />;
      case 103:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/USAAF_Roundel.svg`} alt="USAAF" style={{ width: '20px' }} />;
      case 201:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/Germany_Roundel.svg`} alt="Luftwaffe" style={{ width: '20px' }} />;
      case 101:
        return <img src={`${process.env.PUBLIC_URL}/images/country-icons/USSR_Roundel.svg`} alt="Soviet" style={{ width: '22px' }} />;
      default:
        return 'Unknown';
    }
  };

  // Debounce search
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery.trim().toLowerCase());
    }, 500);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  // Fetch tours once on mount
  useEffect(() => {
    const fetchTours = async () => {
      try {
        setLoadingTours(true);
        const resp = await axios.get('https://il2statsapi.combatbox.net/api/tour');
        if (!resp.data || !resp.data.current_tour || !resp.data.past_tours) {
          setError('Invalid tour data format.');
          return;
        }
        const { current_tour, past_tours } = resp.data;
        setTours([current_tour, ...past_tours]);
        setSelectedTour(current_tour.tour_id);
        setError(null);
      } catch (err) {
        setError(`Error fetching tours: ${err.message}`);
      } finally {
        setLoadingTours(false);
      }
    };
    fetchTours();
  }, []);

  // Fetch rank config if needed
  useEffect(() => {
    const fetchRankConfig = async () => {
      try {
        const response = await axios.get('https://il2statsapi.combatbox.net/api/rankconfig');
        setRankConfig(response.data);
      } catch (err) {
        console.error('Error fetching rank config:', err);
      }
    };
    fetchRankConfig();
  }, []);

  /**
   *  MAIN data fetching effect
   *  We watch [selectedTour, currentPage, debouncedSearch, sortConfig, alive]
   *  so any change triggers a new fetch with the correct &sortby param.
   */
  useEffect(() => {
    if (!selectedTour) return;

    const fetchPilotStats = async () => {
      setLoadingPilots(true);
      setError(null);

      try {
        const aliveParam = alive ? 'true' : 'false'; // "alive=true" or "alive=false"

        // Convert your current sortKey + direction => final sort param
        const finalSort =
          sortConfig.direction === 'ascending'
            ? sortConfig.key
            : `-${sortConfig.key}`;

        let pilotData = [];
        if (debouncedSearch && debouncedSearch.length >= 3) {
          // 1) Fuzzy search the player names
          const searchUrl = `https://il2statsapi.combatbox.net/api/playersearch?tour=${selectedTour}&search=${encodeURIComponent(debouncedSearch)}`;
          const searchResp = await axios.get(searchUrl);

          let players = searchResp.data.map((p) => p.current_name);
          players = players.filter((pName) =>
            pName.toLowerCase().includes(debouncedSearch.toLowerCase())
          );

          // 2) Fetch stats for each player
          const statsPromises = players.map(async (playerName) => {
            const statsUrl = `https://il2statsapi.combatbox.net/api/vlifestats?tour=${selectedTour}&alive=${aliveParam}&sortby=${finalSort}&name=${encodeURIComponent(playerName)}`;
            try {
              const resp = await axios.get(statsUrl);
              return resp.data || [];
            } catch (err) {
              if (err.response && err.response.status === 404) return [];
              throw err;
            }
          });

          const allStats = await Promise.all(statsPromises);
          pilotData = allStats.flat();
        } else {
          // Default listing with finalSort
          const url = `https://il2statsapi.combatbox.net/api/vlifestats?tour=${selectedTour}&alive=${aliveParam}&sortby=${finalSort}&page=${currentPage}`;
          const resp = await axios.get(url);
          console.log(resp);
          pilotData = resp.data || [];
        }

        // Map status for each pilot
        const pilotStatsWithStatus = pilotData.map((pilot) => {
          let statusValue = 1; // Default to Alive (Heart)
          if (pilot.is_dead) {
            statusValue = 3; // Skull
          } else if (pilot.is_captured) {
            statusValue = 2; // Handcuffs
          }
          return { ...pilot, status: statusValue };
        });

        // Sort the data
        const sortedPilotStats = pilotStatsWithStatus.sort((a, b) => {
          const { key, direction } = sortConfig;

          if (key === 'status') {
            return direction === 'ascending'
              ? a.status - b.status
              : b.status - a.status;
          }

          // Existing sort logic for other keys
          if (key) {
            return direction === 'ascending'
              ? a[key] - b[key]
              : b[key] - a[key];
          }

          return 0;
        });

        setPilotStats(sortedPilotStats);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setPilotStats([]);
        } else {
          setError(`Error fetching pilot stats: ${err.message}`);
        }
      } finally {
        setLoadingPilots(false);
      }
    };



    fetchPilotStats();
  }, [
    selectedTour,
    currentPage,
    debouncedSearch,
    sortConfig, // So that changing sort triggers a new fetch
    alive,      // If user toggles alive -> dead, we refetch
  ]);

  // handleSort triggered by column header click
  const handleSort = (headerTitle) => {
    const sortKey = sortByMap[headerTitle];
    if (!sortKey) return; // Not sortable

    let newDirection = 'ascending';
    // Toggle direction if same key
    if (sortConfig.key === sortKey && sortConfig.direction === 'ascending') {
      newDirection = 'descending';
    }
    setSortConfig({ key: sortKey, direction: newDirection });
  };

  function escapeRegex(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
  }


  // Some additional helpers
  const headingTitle = alive ? "Active Roster" : "Memorial Wall";
  const getSortArrow = (headerKey) => {
    if (sortConfig.key === headerKey) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };
  const handleRowClick = (nickname, country, personaId) => {
    const encodedPilot = encodeURIComponent(escapeRegex(nickname));
    const encodedTour = encodeURIComponent(selectedTour);
    const encodedCountry = encodeURIComponent(country);
    const encodedAlive = encodeURIComponent(alive);
    const encodedPersonaId = encodeURIComponent(personaId);

    navigate(
      `/pilot/${encodedPilot}?tour=${encodedTour}&alive=${encodedAlive}&country=${encodedCountry}&persona_id=${encodedPersonaId}`
    );
  };


  const startRange = (currentPage - 1) * pageSize + 1;
  const endRange = currentPage * pageSize;

  if (loadingTours) {
    return <p>Loading tours...</p>;
  }
  if (error) {
    return <p className="text-danger">Error: {error}</p>;
  }



  const tableAlive = alive ? "table-dark" : "table-darker";
  return (
    <div className="container">
      <h2 className="text-uppercase fw-bolder text-center pt-3">{headingTitle}</h2>

      {/* Tour + Search */}
      <div className="mb-3 container-fluid">
        <div className="row row-col-auto justify-content-between">
          <div className="col-12 col-md-6">
            <label>{loadingPilots ? 'Loading pilot stats...' : 'Player Search'}</label>
            <input
              type="text"
              placeholder="Search by Player Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="form-control mb-3"
            />
          </div>

          <div className="col-12 col-md-2">
            <label>Tour</label>
            <select
              id="tourSelect"
              className="form-select"
              value={selectedTour}
              onChange={(e) => {
                setCurrentPage(1);
                setSelectedTour(e.target.value);
              }}
            >
              <option value="">-- Choose a Tour --</option>
              {tours.map((tourObj) => (
                <option key={tourObj.tour_id} value={tourObj.tour_id}>
                  {tourObj.tour_name || `Tour ${tourObj.tour_id}`}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="container pilot-stats">
        <div className="d-flex justify-content-center">
          <div className="table-responsive">
            <table className={`table ${tableAlive} table-striped table-hover table-bordered`} style={{ tableLayout: 'fixed', width: '1270px' }}>
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ width: '2.5%' }}
                  >

                  </th>

                  <th
                    scope="col"
                    style={{ width: '12%', cursor: 'pointer' }}
                    onClick={() => handleSort('Player')}
                  >
                    Player {getSortArrow('nickname')}
                  </th>
                  <th scope="col" style={{ width: '12%' }}>Pilot</th>
                  <th scope="col" className="text-center" style={{ width: '5%' }}>    
                    <img
                    src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/faction.png`}
                    
                    alt="Country"
                    title="Country"
                    style={{ width: '45px' }}
                  />
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: '5%', cursor: 'pointer' }}
                    onClick={() => handleSort('Air Kills')}
                  >
                    <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/air_kills.png`}
                          alt="Air Kills"
                          title="Air Kills"
                          style={{ width: '45px' }}
                        /> {getSortArrow('total_air_kills')}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: '5%', cursor: 'pointer' }}
                    onClick={() => handleSort('Ground Kills')}
                  >
                    <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/ground_kills.svg`}
                          alt="Ground Kills"
                          title="Ground Kills"
                          style={{ width: '50px' }}
                        />  {getSortArrow('total_ground_kills')}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: '5%', cursor: 'pointer' }}
                    onClick={() => handleSort('Sorties')}
                  >
                     <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/takeoffs.svg`}
                          alt="Sorties"
                          title="Sorties"
                          style={{ width: '60px' }}
                        />  {getSortArrow('number_sorties_flown')}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: '5%', cursor: 'pointer' }}
                    onClick={() => handleSort('Flight Time')}
                  >
                      <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/flight_time.svg`}
                          alt="Flight Time"
                          title="Flight Time"
                          style={{ width: '40px' }}
                        /> {getSortArrow('flight_hours')}
                  </th>
                  <th
                    scope="col" className='text-center'
                    style={{ width: '12%', cursor: 'pointer' }}
                    onClick={() => handleSort('Promotion Points')}
                  >
                       <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/rank_up.png`}
                          alt="Promotion Points"
                          title="Promotion Points"
                          style={{ width: '32px' }}
                        /> {getSortArrow('promotion_points')}
                  </th>
                </tr>
              </thead>

              <tbody>
                {debouncedSearch.length > 0 && debouncedSearch.length < 3 ? (
                  <tr>
                    <td colSpan={9} className="text-center text-danger">
                      Please type at least 3 characters to search.
                    </td>
                  </tr>
                ) : pilotStats.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="text-center text-warning">
                      No players found.
                    </td>
                  </tr>
                ) : (
                  pilotStats.map((pilot, idx) => {
                    const promotionPoints = pilot.promotion_points || 0;
                    const progress = promotionPoints;

                    // Determine the status icon
                    // Determine the status icon
                    const getStatusIcon = () => {
                      if (pilot.is_dead) {
                        return (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/skull.png`}
                            alt="Dead"
                            title="Dead"
                            style={{ width: '20px' }}
                          />
                        );
                      }
                      if (pilot.is_captured) {
                        return (
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/handcuffs.png`}
                            alt="Captured"
                            title="Captured"
                            style={{ width: '23px' }}
                          />
                        );
                      }

                      // Check the wound level and return the appropriate heart icon
                      const woundLevel = pilot.wound_level || 0; // Default to 0 if not provided

                      let heartIcon = "full_heart.png"; // Default icon
                      if (woundLevel >= 1 && woundLevel <= 25) {
                        heartIcon = "75_heart.png";
                      } else if (woundLevel >= 26 && woundLevel <= 50) {
                        heartIcon = "50_heart.png";
                      } else if (woundLevel >= 51 && woundLevel <= 75) {
                        heartIcon = "25_heart.png";
                      } else if (woundLevel >= 76 && woundLevel <= 100) {
                        heartIcon = "empty_heart.png";
                      }

                      return (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/${heartIcon}`}
                          alt="Wound Level"
                          title={`Wound Level: ${woundLevel}`}
                          style={{ width: '20px' }}
                        />
                      );
                    };


                    return (
                      <tr
                        key={idx}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleRowClick(pilot.nickname, pilot.country, pilot.persona_id)
                        }
                      >
                        {/* Status Icon Column */}
                        <td className="text-center">{getStatusIcon()}</td>

                        {/* Player Name Column */}
                        <td className={`text-start`}>{pilot.nickname}</td>

                        {/* Active Pilot Column */}
                        <td className={`text-start`}>
                          {(pilot.ShortRank || '') + ' ' + (pilot.PilotName || '')}
                        </td>

                        {/* Country Column */}
                        <td className={`text-center`}>
                          {getCoalitionName(pilot.country)}
                        </td>

                        {/* Other Columns */}
                        <td className={`text-center`}>{pilot.total_air_kills}</td>
                        <td className={`text-center`}>{pilot.total_ground_kills}</td>
                        <td className={`text-center`}>{pilot.number_sorties_flown}</td>
                        <td className={`text-center`}>{pilot.flight_hours}</td>
                        <td className="text-center" style={{ minWidth: '180px' }}>
                          {progress >= 0 ? (
                            <div className="d-flex flex-column align-items-center">
                              <div className="rank-text">{pilot.Rank}</div>
                              <div
                                className="progress-rank-bar progress position-relative"
                                style={{ height: '15px', width: '90%' }}
                              >
                                <span
                                  className="position-absolute w-100 text-center"
                                  style={{
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    fontSize: '12px',
                                    zIndex: 2,
                                  }}
                                >
                                  {promotionPoints}
                                </span>
                                <div
                                  className="progress-bar bg-warning"
                                  role="progressbar"
                                  style={{ width: `${progress}%` }}
                                  aria-label='striped '
                                  aria-valuenow={progress}
                                  aria-valuemin="0"
                                  aria-valuemax="44"
                                >
                                </div>
                              </div>
                            </div>
                          ) : (
                            <span>Demoted for friendly fire.</span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>


            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="row pt-3">
          <nav>
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                  Previous
                </button>
              </li>
              <li className="page-item disabled">
                <span className="page-link">
                  {((currentPage - 1) * pageSize) + 1} - {currentPage * pageSize}
                </span>
              </li>
              <li className="page-item">
                <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default PilotStatsList;
