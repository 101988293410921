// src/components/PilotDetails/CombatRecord.jsx
import React, { useState } from "react";
import PropTypes from "prop-types";
import CombatRecordModal from "./CombatRecordModal"; // The updated modal

const CombatRecord = ({ sortiesData }) => {
  const [selectedSortie, setSelectedSortie] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sortKey, setSortKey] = useState("date_start");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleRowClick = (sortie) => {
    setSelectedSortie(sortie);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedSortie(null);
    setShowModal(false);
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      // Toggle sort direction
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      // New sort key, default to ascending
      setSortKey(key);
      setSortDirection("asc");
    }
  };

  const getFilteredAndSortedData = () => {
      
    // Filter out "aborted" records
    const filteredData = sortiesData.filter(
      (s) => s.overall_status?.toLowerCase().trim() !== "aborted"
    );
  
 
    // Sort the filtered data
    return filteredData.sort((a, b) => {
      const valueA = a[sortKey];
      const valueB = b[sortKey];
      if (sortDirection === "asc") {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });
  };
  
  const filteredAndSortedData = getFilteredAndSortedData();


  const getAdjustedSortieStatusText = (sortie) => {
    switch (sortie) {
      case "killed":
        return "Killed";
      case "captured":
        return "Captured";
      case "disconnected":
        return "Disconnected";
      case "bailed_out":
        return "Bailed out";
      case "in_flight":
        return "In flight";
      case "crash_landed_off_base":
        return "Crash landed off base";
      case "landed_off_base":
        return "Landed off base";
      case "landed_on_base":
        return "Landed at base";
      case "crash_landed_on_base":
        return "Crash landed at base";
      case "destroyed_before_take_off":
        return "Destroyed before take off";
      case "aborted":
        return "Aborted";
      default:
        return 'Unknown';
    }
  };

  return (
    <div className={`pt-5 mb-5 border bg-USSR`}>
      <div className="container-fluid">
        <div className="text-start">
          <h3 className="text-uppercase">Combat Record</h3>
        </div>

        <div className="d-flex justify-content-center">
          <div className="table-responsive">
            <table
              className="table table-striped table-dark table-sm table-bordered table-hover"
              style={{ tableLayout: "fixed", width: "1270px" }}
            >
              <thead>
                <tr>
                  <th
                    className="text-start"
                    style={{ width: "7%", cursor: "pointer" }}
                    onClick={() => handleSort("date_start")}
                  >
                    Date {sortKey === "date_start" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-start"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("aircraft_type")}
                  >
                    Plane {sortKey === "aircraft_type" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("aircraft_damage_percentage")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/plane_damage.svg`}
                      alt="Plane Damage"
                      title="Plane Damage"
                      style={{ width: '60px' }}
                    /> {sortKey === "aircraft_damage_percentage" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "7%", cursor: "pointer" }}
                    onClick={() => handleSort("overall_status")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/takeoffs.svg`}
                      alt="Sortie Status"
                      title="Sortie Status"
                      style={{ width: '60px' }}
                    /> {sortKey === "overall_status" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("air_kills")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/air_kills.png`}
                      alt="Air Kills"
                      title="Air Kills"
                      style={{ width: '45px' }}
                    /> {sortKey === "air_kills" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("ground_kills")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/ground_kills.svg`}
                      alt="Ground Kills"
                      title="Ground Kills"
                      style={{ width: '50px' }}
                    />  {sortKey === "ground_kills" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("formatted_flight_time")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/flight_time.svg`}
                      alt="Flight Time"
                      title="Flight Time"
                      style={{ width: '40px' }}
                    /> {sortKey === "formatted_flight_time" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "5%", cursor: "pointer" }}
                    onClick={() => handleSort("promotion_points")}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/rank_up.png`}
                      alt="Promotion Points"
                      title="Promotion Points"
                      style={{ width: '32px' }}
                    /> {sortKey === "promotion_points" && (sortDirection === "asc" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedData.map((sortie, index) => {
                 const formattedCombatLogDate = (dateString) => {
                  const date = new Date(dateString); // Parse the ISO 8601 date string
                  
                  const day = date.getUTCDate(); // Use getUTCDate to ensure correct day
                  const month = date.toLocaleString("en-US", { month: "short", timeZone: "UTC" }); // Use UTC timezone
                  const year = date.getUTCFullYear(); // Use getUTCFullYear for consistent year
                  
                  const hours = String(date.getUTCHours()).padStart(2, "0"); // Get UTC hours
                  const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Get UTC minutes
                  
                  return `${day} ${month} ${year} ${hours}${minutes}`;
                };
                

                  return (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(sortie)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="text-start">{formattedCombatLogDate(sortie.date_start)}</td>
                      <td>{sortie.aircraft_type}</td>
                      <td className="text-center">{sortie.aircraft_damage_percentage}%</td>
                      <td className="text-start">{getAdjustedSortieStatusText(sortie.overall_status)}</td>
                      <td className="text-center">{sortie.air_kills}</td>
                      <td className="text-center">{sortie.ground_kills}</td>
                      <td className="text-center">{sortie.formatted_flight_time}</td>
                      <td className="text-center">
                        {sortie.promotion_points > 0
                          ? `+${sortie.promotion_points}`
                          : sortie.promotion_points}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* The updated CombatRecordModal with all logic inside */}
      <CombatRecordModal
        show={showModal}
        onHide={closeModal}
        selectedSortie={selectedSortie}
      />
    </div>
  );
};

CombatRecord.propTypes = {
  sortiesData: PropTypes.arrayOf(
    PropTypes.shape({
      date_start: PropTypes.string.isRequired,
      aircraft_type: PropTypes.string.isRequired,
      payload: PropTypes.string,
      aircraft_mods: PropTypes.arrayOf(PropTypes.string),
      fuel: PropTypes.number,
      aircraft_damage_percentage: PropTypes.number,
      formatted_flight_time: PropTypes.string,
      overall_status: PropTypes.string,
      promotion_points: PropTypes.number,
      ammo: PropTypes.string,
      air_kills: PropTypes.number,
      air_kill_assists: PropTypes.number,
      ground_kills: PropTypes.number,
      killboard_pve: PropTypes.string,
      killboard_pvp: PropTypes.string,
    })
  ).isRequired,
};

export default CombatRecord;
