// src/pages/Pilots.jsx
import React from 'react';
import PilotStatsList from '../components/PilotStatsList';

function Pilots() {
  // "Active" means alive = true
  return (
    <div>
      <PilotStatsList alive={true} headingTitle="Active Pilots" />
    </div>
  );
}

export default Pilots;
