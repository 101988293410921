import React from 'react';

function ServerInfo() {
  return (
    <div>
      <div className='container'>
        <h2 className="text-uppercase pt-3 text-center fw-bold">
          Server Rules
        </h2>

        <div className='col-12'>
          <div className='mt-3'>

            <div className="container">
              {/* Rules */}
              <div className="row border-bottom py-4">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/be-nice.webp`}
                    alt="Be Nice"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8">
                  <h3 className="text-uppercase">Be nice</h3>
                  <p>
                    A little bit of smack talk is expected, but don’t get nasty. All chat is logged. Avoid discussing politics,
                    religion, or sensitive topics. Slurs of any kind will not be tolerated. Offenders will be banned.
                  </p>
                </div>
              </div>

              <div className="row border-bottom py-4">
                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/play-fair.webp`}
                    alt="Play Fair"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8 order-md-1">
                  <h3 className="text-uppercase">Play Fair</h3>
                  <p>
                    If you find something unbalanced, broken, or someone breaking the rules, report it via <a href='https://discord.gg/PQN65PP92H' className='link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover'>Discord</a>. Exploiters and
                    griefers will be banned.
                  </p>
                </div>
              </div>

              <div className="row border-bottom py-4">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/no-chute-killing.webp`}
                    alt="No Chute Killing"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8">
                  <h3 className="text-uppercase">No chute killing</h3>
                  <p>
                    Intentional chute killing and gratuitous passes attacking crashed or ditched aircraft are not allowed. Attacking
                    aircraft taking off or landing at enemy airfields is permitted.
                  </p>
                </div>
              </div>

              <div className="row border-bottom py-4">
                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/disconnecting.webp`}
                    alt="Don't Disconnect"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8 order-md-1">
                  <h3 className="text-uppercase">Disconnecting Ends Your Streak</h3>
                  <p>
                    Disconnecting shortly after being damaged awards a kill to your opponent and ends your streak.
                  </p>
                </div>
              </div>

              <div className="row border-bottom py-4">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/undamaged.webp`}
                    alt="Do not bail out of an undamaged plane"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8">
                  <h3 className="text-uppercase">Don't Bail out of an undamaged plane</h3>
                  <p>Exiting an undamaged plane incurs a stats penalty.</p>
                </div>
              </div>

              <div className="row border-bottom py-4">
                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/airfield.webp`}
                    alt="Airfield Etiquette"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8 order-md-1">
                  <h3 className="text-uppercase">Follow Proper Airfield Etiquette</h3>
                  <p>Your airfield is a shared resource. Exercise care and use the designated taxiways and runways.</p>
                </div>
              </div>

              <div className="row py-4">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/rules/admins.webp`}
                    alt="Admins have final discretion"
                    className="img-fluid"
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="col-md-8">
                  <h3 className="text-uppercase">Admins have final discretion</h3>
                  <p>These rules are not comprehensive. Admins reserve the right to use their judgment to maintain fair play. Administrators: Alonzo, Barnacles, Haluter, RufusK, Sketch, Talon, Wim</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ServerInfo;
