import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RemainingSupplyGraph from '../RemainingSupplyGraph'; // Adjust the path as needed

const countryImages = {
  'United States': 'USAAF_Roundel.svg',
  'Great Britain': 'RAF_Roundel.svg',
  'Germany': 'Germany_Roundel.svg',
  'USSR': 'USSR_Roundel.svg',
};

function DisplayAirfieldsList({ airfields, showActiveOnly }) {
  const [sortConfig, setSortConfig] = useState({ key: 'SupplyLevel', direction: 'decending' });

  const sortedAirfields = [...airfields].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const compareValue = (key) => {
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        return a[key].localeCompare(b[key]);
      }
      return a[key] - b[key];
    };

    const modifier = sortConfig.direction === 'ascending' ? 1 : -1;
    return compareValue(sortConfig.key) * modifier;
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderRows = () => {
    const filteredAirfields = showActiveOnly
      ? sortedAirfields.filter((airfield) => airfield.ActiveToday)
      : sortedAirfields;

    return filteredAirfields.map((item, index) => {
      const arrowStyle = { transform: `rotate(${item.RunwayBearing}deg)` };
      const countryImage = countryImages[item.Country] || 'default-placeholder.svg';

      return (
        <tr key={index}>
          <th scope="row">
            <Link
              to={`/airfield/${encodeURIComponent(item.Name)}`}
              state={{ airfield: item }}
              className="text-white text-decoration-none"
            >
              {item.Name}
            </Link>
          </th>
          <td className="text-center">
            <img
              src={`/images/country-icons/${countryImage}`}
              alt={item.Country}
              title={item.Country}
              style={{
                width: '20px',
                height: '20px',
                ...(countryImage === 'RAF_Roundel.svg' && { width: '22px', height: '22px' }),
                ...(countryImage === 'Germany_Roundel.svg' && { width: '18px', height: '18px' }),
              }}
            />
          </td>
          <td>
            <RemainingSupplyGraph
              supplyLevel={item.SupplyLevel}
              maxSupplyLevel={item.MaxSupplyLevel || 200}
            />
          </td>
          <td>
            <i
              className="fa-solid fa-arrow-up"
              style={arrowStyle}
              title={`Runway Bearing: ${item.RunwayBearing}°`}
            ></i>
            <span className="ps-2">{item.RunwayBearing}°</span>
          </td>
          <td>{item.RunwayIsConcrete ? 'Concrete' : 'Grass'}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-bordered table-dark table-responsive table-hover table-striped">
      <thead>
        <tr>
          <th onClick={() => requestSort('Name')} style={{ cursor: 'pointer' }}>
            Name {sortConfig.key === 'Name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
          <th>
            Country
          </th>
          <th onClick={() => requestSort('SupplyLevel')} style={{ cursor: 'pointer' }}>
            Supply {sortConfig.key === 'SupplyLevel' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => requestSort('RunwayBearing')} style={{ cursor: 'pointer' }}>
            Runway {sortConfig.key === 'RunwayBearing' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => requestSort('RunwayIsConcrete')} style={{ cursor: 'pointer' }}>
            Type {sortConfig.key === 'RunwayIsConcrete' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
          </th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

DisplayAirfieldsList.propTypes = {
  airfields: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Country: PropTypes.string.isRequired,
      SupplyLevel: PropTypes.number.isRequired,
      MaxSupplyLevel: PropTypes.number,
      RunwayBearing: PropTypes.number.isRequired,
      RunwayIsConcrete: PropTypes.bool.isRequired,
      ActiveToday: PropTypes.bool.isRequired,
    })
  ).isRequired,
  showActiveOnly: PropTypes.bool,
};

DisplayAirfieldsList.defaultProps = {
  showActiveOnly: false,
};

export default DisplayAirfieldsList;
