import React, { useState } from 'react';
import Modal from '../../components/Modal';


function ServerCB_Radio() {

  const stdcallsigns = [
    { name: 'Anvil', image: 'badge_anvil' },
    { name: 'Banshee', image: 'badge_banshee' },
    { name: 'Cowboy', image: 'badge_cowboy' },
    { name: 'Dropkick', image: 'badge_dropkick' },
    { name: 'Eagle' }, // No image specified
    { name: 'Falcon', image: 'badge_falcon' },
    { name: 'Grizzly' }, // No image specified
    { name: 'Hellcat' }, // No image specified
    { name: 'Iceman', image: 'badge_iceman' },
    { name: 'Jackal' }, // No image specified
    { name: 'Nighthawk' }, // No image specified
    { name: 'Lonestar' }, // No image specified
    { name: 'Maniac' }, // No image specified
    { name: 'Neptune' }, // No image specified
    { name: 'Outlaw' }, // No image specified
    { name: 'Panther' }, // No image specified
    { name: 'Quicksand', image: 'badge_quicksand' },
    { name: 'Raven' }, // No image specified
    { name: 'Scorpion' }, // No image specified
    { name: 'Tomahawk' }, // No image specified
    { name: 'Uncle' }, // No image specified
    { name: 'Voodoo' }, // No image specified
    { name: 'Wizard' }, // No image specified
    { name: 'Yamaha' }, // No image specified
    { name: 'Zombie' }, // No image specified
  ];

  const sqdcallsigns = [
    { name: 'Bumper', squadName: '41 Squadron' }, // No image specified
    { name: 'Circus', squadName: 'F.Circus, IRRE', image: 'badge_circus' },
    { name: 'Dollar', squadName: '356th FS, 357th FS' }, // No image specified 
    { name: 'Firebird', squadName: 'Duxford Wing' }, // No image specified
    { name: 'Guido', squadName: '332nd FG', image: 'badge_guido' },
    { name: 'Havoc', squadName: 'vacant' }, // No image specified
    { name: 'Lafayette', squadName: 'US103, EMS' },
    { name: 'Misfit', squadName: 'Misfits' }, // No image specified
    { name: 'Paladin', squadName: '332nd FG' }, // No image specified
    { name: 'Raider', squadName: '78th FG, 332nd FG' }, // No image specified
    { name: 'Red', squadName: 'Red Flight' }, // No image specified    
    { name: 'Renegade', squadName: '332nd FG' },
    { name: 'Shark', squadName: 'Shark Squad' },
    { name: 'Tulip', squadName: 'Black Tulips', image: 'badge_tulip' },
    { name: 'Yardstick', squadName: '332nd FG' }, // No image specified
  ];

  const chn1commands = [
    { example: '“Longbow, Wizard-1 in grid 2418” \n“Command, this is Yamaha Lead in grid 1015”', explanation: 'Update the GCI bot with your current location. If there is urgent activity nearby, the bot will direct you to it, otherwise the bot will simply acknowledge your update and remember your location. If future action occurs nearby, the bot will know where you are and coordinate you with other aircraft.' },
    { example: '“Control, Nighthawk-2 in grid 3130, request assignment.” \n“Kenway, this is Uncle-3 in grid 1015, request mission.” \n“Command, this is Tomahawk-2 in grid 2024, need orders.”', explanation: 'Request an appropriate mission for your location and aircraft role. For fighters, the bot suggests nearby friendly objectives that need protection or nearby enemy targets where friendlies need cover. For bombers, the bot will suggest a nearby enemy objective that you can attack. The bot knows if you have bombs/rockets and will treat you as a fighter or bomber. JaBos will automatically be treated as fighters once they have dropped their bombs.' },
    { example: '“Command, Maniac-Lead, strike package en route to grid 1716, ETA 5 minutes.” \n“Kenway this is Tomahawk-2, en-route to grid 2526.” \n“Longbow, Wizard-1, bomber target 1812.”', explanation: 'Declare a bombing objective and (optionally) your ETA. GCI should respond with the enemy target name, indicating it understands your intentions. A few minutes before you reach the target, GCI will look for a nearby fighter and vector them to cover you. Shortly before reaching the target, GCI will give you a sitrep for your target.' },
    { example: '“Kenway this is Tomahawk-2, ETA 4 minutes.', explanation: 'Update your ETA to target. This is usable at any point in your flight, and you should give an updated ETA to better help the bot coordinate fighter cover for your strike.' },
    { example: '“Kenway, this is Anvil-1 in grid 1015, request cover.”', explanation: 'Ask for immediate cover, regardless of whether you previously declared a target. The GCI bot will attempt to locate a nearby untasked fighter and vector it to your location.' },
    { example: '“Command, Tomahawk-1 in grid 1817, request picture.”', explanation: 'Ask GCI for a “picture” of action in your immediate vicinity. If there’s a “hot” location or heavy fighting within about 20km, it will let you know. It will also look for any enemies it knows about in your sector, and either say “picture clear” if it can’t find anything, or it will warn you of the general amount of enemy activity. The bot is not all-seeing, so even if it says “picture clear” you cannot be sure you’re actually completely clear of bandits, but if it says “caution, heavy enemy activity” it really knows there are enemies nearby.' },
    { example: '“Control, request situation report.”', explanation: 'Ask GCI to report on the targets that friendlies and enemies have been attacking during the current mission.' },
    { example: '“Longbow, this is Voodoo-5 in grid 2411, need vector to friendly airfield.” \n“Control, this is Arrow-Lead in grid 1017, request bearing home.”', explanation: 'Request a compass heading for you to fly to the nearest friendly airfield.' },
    { example: '“Command, Wizard-1 in grid 1015, RTB.” \n“Control, this is Uncle-3, returning to base, damaged.”', explanation: 'Declare that you are returning to base. If you include a grid reference or were recently involved in action, the bot will acknowledge and give you a bearing to the nearest friendly field. Declaring that you are RTB also marks you as “untaskable” so the bot will not try to assign you cover or intercept missions on your way home.' },
    { example: '“Longbow, say again.” \n“Command, repeat your last transmission.”', explanation: 'Ask for a repeat of the last transmission. This only works if you include “Tower”, “Command”, “Longbow” or another specific station name, to avoid clashes with pilots chatting to each other. This command is useful if the GCI bot gets stepped on by another player.' }
  ];

  const chn2commands = [
    { example: '“Tower, radio check” \n“Command, comms check” \n“Mic check”', explanation: 'Request a radio check.' },
    { example: '“Tower, request call sign.”', explanation: 'Request a call sign for the duration of your mission. Call signs persist through death but are reset on map roll. If you forget your call sign, just do another request or a radio check and the bot will remind you.' },
    { example: '“Tower, request call sign Uncle-4”', explanation: 'Request a specific call sign. If it is available, the bot will assign it to you. Sometimes it’s hard to get the bot to hear you correctly. Try distinctive call signs like “Tomahawk”, “Raven”, “Voodoo”, “Eagle”, or “Quicksand”.' },
    { example: '“Tower, flight of three, type 109, request call sign.”', explanation: 'Request a call sign for a flight of up to five aircraft. Only the flight lead should do this, and the bot will assign the “lead” call sign to whoever made the radio call. Wingmates should follow up with “Tower, request call sign Voodoo-2” (or whichever call sign it assigned) to register that they are wingmen.' },
    { example: '“Tower, permission to taxi” \n“Ground, this is Tomahawk-5 request taxi”', explanation: 'Request taxi clearance.' },
    { example: '“Tower, request takeoff clearance” \n“Tower, this is Voodoo-2, ready to take off”', explanation: 'Request takeoff clearance. You may be denied takeoff clearance if an emergency landing is in progress at your airfield. Clearances are advisory only, so always check for landing aircraft on final before taking the active runway, even if cleared.' },
    { example: '“Tower, request airfield information.” \n“Tower this is Anvil-2 in grid 1017, request runway in use.” \n“Airfield, request advisory.”', explanation: 'Request current airfield conditions such as the runway in use and traffic. Note: the bot doesn’t magically know your location, so you may need to do “Tower, wizard-1 in grid 1015, request airfield information” to let it know where you are. After that a landing request with no grid reference should work.' },
    { example: '“Tower, Banshee-2 in grid 1012, request landing.” \n“Melsbroek Airfield, Tomahawk-Lead, request landing.” \n“Control, Banshee-2 inbound for landing.”', explanation: 'Pilots can ask the tower for a landing clearance. Due to the fact that many pilots are not on SRS, the tower can only give an advisory, but it should help you on your approach. You need to give a grid at some point in your interaction with the tower, to establish that you’re nearby, but once you’ve done that you can request clearance without including a grid.' },
    { example: '“Tower, Maniac-3 in grid 2417, emergency landing.”', explanation: 'Declare an emergency at a nearby airfield. The tower should give you landing clearance and advise nearby traffic, as well as denying takeoff clearances during the emergency.' },
  ];

  const [selectedBadge, setSelectedBadge] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleBadgeClick = (badge) => {
    setSelectedBadge(badge);
    setShowModal(true);
  }
  const getSelectedBadgeName = (selectedBadge) => {
    return `${selectedBadge?.name}`;
  };


  const setSelectedBadgePath = (selectedBadge) => {
    console.log("selected badge is ", selectedBadge);
    if(!selectedBadge || !selectedBadge.image){
      return `${process.env.PUBLIC_URL}/images/callsigns/callsign-placeholder.png`;
    }
    return `${process.env.PUBLIC_URL}/images/callsigns/${selectedBadge?.image}.png`;
  };


  const closeModal = () => {
    setShowModal(false);
    setSelectedBadge(null);
  }

  return (
    <div>
      <h2 className="text-uppercase fw-bolder text-center pt-3">
        CB Radio
      </h2>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div className="cbradio-container">

          <h3>“CB Radio” and AI Ground Controllers</h3>
          <p>
            Combat Box features artificial intelligence that provides strategic mission updates as well as speech recognition for pilot-to-control communications over <a href='/server-info/srs' className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">SRS</a>. You may hear AI voices on channel 1 radioing that targets are under attack or warning of heavy fighting, or broadcasting airfield conditions and traffic updates on channel 2.</p>

          <p>If you wish to use the speech recognition, first join Channel 2 and say “tower, radio check”. The AI should respond that it can hear you. You can then say “tower, request call sign” in order to be assigned a call sign. Listen carefully and remember your call sign. If you forget your call sign, do another radio check and the bot will use your call sign in response. You can also request a specific call sign if you prefer (see below).</p>

          <p>Radio calls work best if you use the full radio call sequence each time, i.e. [ident you are talking to] [your ident] [your location, if relevant] [your request]. Due to the way IL-2 works, the bot only knows your location if you recently spawned, took off or landed, engaged in combat, or explicitly told it your location. On approach to an airfield, for example, you might have to tell it your grid before (or during) an airfield information request, but then you can ask for a landing and it’ll know where you are.</p>
          <p>“Kenway, this is Voodoo-2 in grid 1218, request mission” will be more reliable than “Voodoo-2, 1218, need mission.” Abbreviated calls do work, but if you’re having trouble getting a response from the bot, try the long/formal version.</p>

          <p>The following tables show requests that players can ask the CB Radio bot. Channel 1  is the command and coordination channel. Monitor Channel 1 for important messages from Allied or German command and coordinate with other pilots.
            Channel 2 is the tower and airfield operations channel. Ensure you switch to, or monitor, Channel 2 when operating near an airfield. Channels 3-25 are used for operation-specific communications. At pilot’s discretion, use these channels for specific operations such as CAP, ground strike, or intra-flight comms.</p>

          <h4 className='pt-3'>Channel 2 Requests</h4>
          <p><i>Request a callsign; request a comms check; request airfield information; request clearance to taxi, takeoff, or land; declare an emergency.</i></p>

          <div className="table-responsive mt-3 justify-content-center align-items-center d-flex flex-column">
            <table className="table table-dark table-striped table-bordered" style={{ tableLayout: "fixed"}}>
              <thead className="thead-dark">
                <tr>
                  <th style={{ width: '30%' }}>Example</th>
                  <th style={{ width: '70%' }}>Explanation</th>
                </tr>
              </thead>
              <tbody>
                {chn2commands.map((chn2commands, index) => (
                  <tr key={index}>
                    <td>{chn2commands.example.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <p />
                      </React.Fragment>
                    ))}
                    </td>
                    <td>{chn2commands.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h4 className='pt-3'>Channel 1 Requests</h4>
          <p><i>Request tasking; request the current picture; request the current situation; request a vector; declare RTB.</i></p>

          <div className="table-responsive mt-3">
            <table className="table table-dark table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Example</th>
                  <th>Explanation</th>
                </tr>
              </thead>
              <tbody>
                {chn1commands.map((chn1commands, index) => (
                  <tr key={index}>
                    <td>{chn1commands.example.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <p />
                      </React.Fragment>
                    ))}
                    </td>
                    <td>{chn1commands.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="full-width-bar mt-4"></div>

          <div className="row pt-3">
            <div className='col-lg-6 col-12'>

              <h4>Standard Callsigns</h4>

              <div className="table-responsive mt-3">
                <table className="table table-dark table-striped table-bordered"
                style={{tableLayout: "fixed"}}>
                  <thead className="thead-dark">
                    <tr>
                      <th style={{width: '30%'}}>Callsign</th>
                      <th className='text-center' style={{width: '70%'}}>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stdcallsigns.map((stdcallsign, index) => (
                      <tr key={index}>
                        <td>{stdcallsign.name}</td>
                        <td className='text-center'>
                          <img
                            src={
                              stdcallsign.image
                                ? `${process.env.PUBLIC_URL}/images/callsigns/${stdcallsign.image}-512.png`
                                : `${process.env.PUBLIC_URL}/images/callsigns/callsign-placeholder-512.png`
                            }
                            alt={stdcallsign.name || 'Default Callsign'}
                            style={{width: 'auto', maxHeight: '150px'}}
                            onClick={() => handleBadgeClick(stdcallsign)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='col-lg-6 col-12'>

              <h4>Squadron Callsigns</h4>

              <div className="table-responsive mt-3">
                <table className="table table-dark table-striped table-bordered"
                  style={{tableLayout: "fixed"}}>
                  <thead className="thead-dark">
                    <tr>
                      <th style={{width: '30%'}}>Callsign</th>
                      <th className='text-center' style={{width: '70%'}}>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sqdcallsigns.map((sqdcallsign, index) => (
                      <tr key={index}>
                        <td>
                          <div className='col-12'>{sqdcallsign.name}</div>
                          <div className='col-12 fst-italic'>{sqdcallsign?.squadName || ""}</div>
                        </td>
                        <td className='text-center'>
                        <img
                            src={
                              sqdcallsign.image
                                ? `${process.env.PUBLIC_URL}/images/callsigns/${sqdcallsign.image}-512.png`
                                : `${process.env.PUBLIC_URL}/images/callsigns/callsign-placeholder-512.png`
                            }
                            alt={sqdcallsign.name || 'Default Callsign'}
                            style={{width: 'auto', maxHeight: '150px'}}
                            onClick={() => handleBadgeClick(sqdcallsign)}
                          />

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal} // Control visibility
        onHide={closeModal} // Handle modal close
        title={getSelectedBadgeName(selectedBadge)} // Default title based on selected award
        body={(() => {

          // Default modal for awards
          return (
            <>
              <div className="row">
                <img
                  className="awards-medals awards-medals-ribbon"
                  src={setSelectedBadgePath(selectedBadge)}
                  alt={setSelectedBadgePath(selectedBadge)|| "Unknown Award"}
                />
              </div>
            </>
          );
        })()}
        size="lg"
      />
    </div>
  );
}

export default ServerCB_Radio;

