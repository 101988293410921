// src/components/PilotDetails/ActivePilot.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReportTemplate from "./ReportTemplate";
import { sanitizeMissionReport } from "./helpers/sanitizeMissionReport";
import Modal from '../../components/Modal';


export default function ActivePilot({ pilotData, sortiesData, isAlive }) {
    const [allSortieAars, setAllSortieAars] = useState([]);
    const [rankConfig, setRankConfig] = useState(null);
    const [showModal, setShowModal] = useState(false);

    function escapeRegex(str) {
        // This pattern escapes all special chars that have meaning in RegEx.
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }


    // 1) Fetch rank config
    useEffect(() => {
        const fetchRankConfig = async () => {
            try {
                const response = await axios.get("https://il2statsapi.combatbox.net/api/rankconfig");
                setRankConfig(response.data);
            } catch (err) {
                console.error("Error fetching rank config:", err);
            }
        };
        fetchRankConfig();
    }, []);

    // 2) Fetch all Sortie AARs
    useEffect(() => {
        if (!pilotData || !sortiesData || sortiesData.length === 0) return;

        const formatTimestamp = (startDate) => {
            if (!startDate) return "";
            const datePart = startDate.split("T")[0].replace(/-/g, "");
            const timePart = startDate.split("T")[1].replace(/:/g, "").replace("Z", "");
            return `${datePart}_${timePart}`;
        };

        const fetchAllSortieAars = async () => {
            const sanitizedPilotName = escapeRegex(pilotData.nickname)
                .toLowerCase()
                .replace(/[^a-zA-Z0-9\-_.]/g, "_");

            try {
                const sortieAarPromises = sortiesData.map(async (sortie) => {
                    const formattedTimestamp = formatTimestamp(sortie.date_start);
                    const url = `https://m2.combatbox.net/sorties/sortie-${sanitizedPilotName}-${formattedTimestamp}.json`;

                    try {
                        const response = await axios.get(url);
                        return response.data || null;
                    } catch (err) {
                        console.error(`Error fetching sortie AAR for ${url}`, err);
                        return null;
                    }
                });

                const sortieAars = await Promise.all(sortieAarPromises);
                setAllSortieAars(sortieAars.filter((aar) => aar !== null));
            } catch (err) {
                console.error("Error fetching all sortie AARs:", err);
                setAllSortieAars([]);
            }
        };

        fetchAllSortieAars();
    }, [pilotData, sortiesData]);

    // 3) If no pilot data
    if (!pilotData) {
        return <p>No active pilot data found.</p>;
    }

    // 4) For rank system
    const countryMap = {
        101: "Sov",
        102: "Raf",
        103: "Usa",
        201: "Ger",
    };

    if (!rankConfig || !rankConfig.Ranks) {
        return <td>No rank config yet</td>;
    }

    const rankSystem = rankConfig.Ranks[countryMap[pilotData.country]] || [];
    const promotionPoints = pilotData.promotion_points || 0;

    let currentRank = rankSystem[0];
    let nextRank = rankSystem[1];

    for (let i = 0; i < rankSystem.length; i++) {
        if (promotionPoints >= rankSystem[i].Points) {
            currentRank = rankSystem[i];
            nextRank = rankSystem[i + 1] || null;
        } else {
            break;
        }
    }

    const progress = nextRank
        ? (promotionPoints / nextRank.Points) * 100
        : 100;

    const currentRankIndex = rankSystem.indexOf(currentRank) + 1;

    // 5) “Flight Log” data
    const sanitizedSortieAars = allSortieAars.map((aar) =>
        sanitizeMissionReport(aar, pilotData)
    );

    const sortedAndReversedSortieAars = sanitizedSortieAars.reverse();
    // If you want to remove the last entry:
    // sortedAndReversedSortieAars.pop();

    // Helper for country background color
    const getCountryBackgroundColorClass = (countryCode) => {
        switch (countryCode) {
            case 101:
                return "bg-USSR";
            case 102:
                return "bg-RAF";
            case 103:
                return "bg-USAAF";
            case 201:
                return "bg-Luft";
            default:
                return "bg-dark";
        }
    };

    const countryBackgroundColor = getCountryBackgroundColorClass(pilotData.country);

    // helper
    const getPilotImagePath = (pilotData) => {
        if (!pilotData || !pilotData.PilotPortrait) return "";
        const folderName = countryMap[pilotData.country];
        return `${process.env.PUBLIC_URL}/images/pilot-headshots/${folderName}/${pilotData.PilotPortrait}-512px.webp`;
    };

    const getPilotRankImagePath = (pilotData) => {
        if (!pilotData || !pilotData.PilotPortrait) return "";
        const folderName = countryMap[pilotData.country];
        return `${process.env.PUBLIC_URL}/images/pilot-ranks/${folderName}/${pilotData.RankImage}`;
    };

    const imageHeadshotPath = getPilotImagePath(pilotData);
    const imageRankPath = getPilotRankImagePath(pilotData);

    // Sortie status text
    const getSortieStatusText = (sortieStatus) => {
        switch (sortieStatus) {
            case "landed_on_base":
                return "Landed at base";
            case "aborted":
                return "Aborted";
            case "disconnected":
                return "Disconnected";
            case "crash_landed_on_base":
                return "Crash landed at base";
            case "destroyed_before_take_off":
                return "Destroyed before take off";
            case "bailed_out":
                return "Bailed out";
            case "in_flight":
                return "In flight";
            case "crash_landed_off_base":
                return "Crash landed near base";
            case "landed_off_base":
                return "Landed off base";
            default:
                return "Unknown";
        }
    };


    // map for healt status
    const heartStatusMap = {
        "healthy": ["full_heart.png", "full_heart.png", "full_heart.png", "full_heart.png"],
        "lightly wounded": ["empty_heart.png", "full_heart.png", "full_heart.png", "full_heart.png",],
        "seriously wounded": ["empty_heart.png", "empty_heart.png", "full_heart.png", "full_heart.png",],
        "gravely wounded": [ "empty_heart.png", "empty_heart.png", "empty_heart.png", "full_heart.png",],
        "dead": ["empty_heart.png", "empty_heart.png", "empty_heart.png", "empty_heart.png"],
    };





    // MODAL for pilot portrait

    const handlePortraitClick = () => {
        setShowModal(true);
    };


    const closeModal = () => {
        setShowModal(false);
    };


    // Render
    return (
        <div className={`pt-5 mb-5 border ${countryBackgroundColor}`}>
            <div className="container-fluid">
                <h3>
                    PROFILE
                </h3>
                <div className="row row-col-auto">
                    {/* Pilot Image Block */}
                    <div className="col-md-3 col-12">
                        <div className="col-12">
                            <div className="position-relative">
                                {/* Bottom Image - Portrait */}
                                {imageHeadshotPath ? (
                                    <img
                                        src={imageHeadshotPath}
                                        alt={`${pilotData.PilotName}'s portrait`}
                                        className="img-fluid rounded img-thumbnail"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            cursor: "pointer",
                                        }}
                                        onClick={handlePortraitClick}
                                    />
                                ) : (
                                    <p>No portrait available</p>
                                )}

                                {/* Top Image - Status Overlay */}
                                {pilotData.is_dead ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/pilot-headshot-status/KIA.png`}
                                        alt="KIA"
                                        className="position-absolute top-50 start-50 translate-middle"
                                        style={{
                                            width: "95%",
                                            height: "auto",
                                        }}
                                    />
                                ) : pilotData.is_captured ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/pilot-headshot-status/POW.png`}
                                        alt="Captured"
                                        className="position-absolute top-50 start-50 translate-middle"
                                        style={{
                                            width: "95%",
                                            height: "auto",
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>

                        {/* Pilot Info */}
                        <div className="d-flex pb-3 justify-content-center">
                            <h2>
                                {pilotData.Rank} {pilotData.PilotName}
                            </h2>
                        </div>

                        {/* Pilot Flight Record */}
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-dark table-sm table-bordered">
                                    <tbody>
                                        {/* Rank progress bar */}
                                        <tr>
                                            <th className="text-center pb-3" colSpan="2">
                                                <div className="d-flex flex-column align-items-center">
                                                    <div>Rank {currentRankIndex} - {currentRank.Rank}</div>
                                                    <div className="progress-rank-bar progress" style={{ height: "20px", width: "95%" }}>
                                                        <div
                                                            className="progress-bar text-center bg-warning text-black"
                                                            role="progressbar"
                                                            style={{ width: `${progress}%` }}
                                                            aria-valuenow={progress}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        >
                                                            {`${promotionPoints}/${nextRank ? nextRank.Points : promotionPoints}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Medical Status</th>
                                            <td className="text-start">
                                                {pilotData.pilot_health ? (
                                                    <div className="text-center">
                                                        {(heartStatusMap[pilotData.pilot_health] || ["empty_heart.png", "empty_heart.png", "empty_heart.png", "empty_heart.png"]).map((heart, index) => {
                                                            return (
                                                                <img
                                                                    key={index}
                                                                    src={`${process.env.PUBLIC_URL}/images/pilot-list-status-icons/${heart}`}
                                                                    alt={heart === "full_heart.png" ? "Full Heart" : "Empty Heart"}
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "4px", // Spacing between hearts
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                ) : (
                                                    "Unknown"
                                                )}
                                            </td>


                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Squadron</th>
                                            <td className="text-start">
                                                {typeof pilotData.squad_name === "string" && pilotData.squad_name.trim() !== ""
                                                    ? pilotData.squad_name
                                                    : "No Squadron"}
                                            </td>


                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Location</th>
                                            <td className="text-start">
                                                {typeof pilotData.eastern_front === 'boolean'
                                                    ? pilotData.eastern_front
                                                        ? 'Eastern Front'
                                                        : 'Western Front'
                                                    : 'Unknown'}
                                            </td>

                                        </tr>

                                        <tr>
                                            <th className="text-center" colSpan="2" style={{ width: '50%' }}>------</th>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Air Kills</th>
                                            <td className="text-start">{pilotData.total_air_kills}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Ground Kills</th>
                                            <td className="text-start">{pilotData.total_ground_kills}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Sorties</th>
                                            <td className="text-start">{pilotData.number_sorties_flown}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Flight Time</th>
                                            <td className="text-start">{pilotData.flight_hours} hours</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Days Flown</th>
                                            <td className="text-start">{pilotData.days_flown} days</td>
                                        </tr>

                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Bailouts</th>
                                            <td className="text-start">{pilotData.bailout_count}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Aircraft Lost</th>
                                            <td className="text-start">{pilotData.lost_aircraft_count}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Wounded Count</th>
                                            <td className="text-start">{pilotData.wounded_count}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-center" colSpan="2" style={{ width: '50%' }}>------</th>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Friendly Air Kills</th>
                                            <td className="text-start">{pilotData.total_friendly_air_kills}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Friendly Ground Kills</th>
                                            <td className="text-start">{pilotData.total_friendly_ground_kills}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-start ps-2" style={{ width: '50%' }}>Discos</th>
                                            <td className="text-start">{pilotData.disco_count}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Flight Log */}
                    <div className="col-md-9 col-12">
                        <h3 className="col-12 text-uppercase">Flight Log</h3>
                        <div className="col-12 overflow-auto pe-1" style={{ maxHeight: "1000px" }}>
                            <div>
                                {sortedAndReversedSortieAars.map((sanitizedReport, index) =>
                                    sanitizedReport ? (
                                        <div key={index} className="sortie-aar">
                                            <ReportTemplate sanitizedReport={sanitizedReport} />
                                        </div>
                                    ) : null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {/* Modal */}
                <Modal
                    show={showModal} // Control visibility
                    onHide={closeModal} // Handle modal close
                    title={`${pilotData.PilotName}'s Portrait`} // Modal title
                    body={(
                        <div className="text-center">
                            <img
                                src={imageHeadshotPath.replace("-512px", "")} // Use larger version of the image if available
                                alt={`${pilotData.PilotName}'s portrait`}
                                className="img-fluid rounded"
                                style={{ maxHeight: "80vh", width: "auto" }} // Style for better display
                            />
                        </div>
                    )}
                    size="lg"
                />
            </div>

        </div>
    );
}
