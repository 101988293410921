import React from 'react';

function ServerSRS() {
  return (
    <div>
      <div className='container'>
        <h2 className="text-uppercase pt-3 text-center fw-bold">
          SRS Information
        </h2>

        <div className='col-12'>
          <div className='mt-3'>

            <div className="container">
              {/* Rules */}
              <div className="row border-bottom py-4">
                <h4 className='text-center'>
                  <a
                    href="https://github.com/ciribob/IL2-SimpleRadioStandalone/releases/download/1.0.1.4/IL2-SRS-AutoUpdater.exe"
                    className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    DOWNLOAD SRS HERE
                  </a>
                </h4>
                <p className='pt-4'>
                  Thanks to the game developers and <a href='https://github.com/ciribob' className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Ciribob</a>, pilots can enjoy realistic radio communications integrated into their IL-2 experience. You can see the latest release of SRS through this{" "}
                  <a
                    href="https://github.com/ciribob/IL2-SimpleRadioStandalone/releases/"
                    className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  >
                    link
                  </a>
                  . For full details and installation instructions, please watch the video below or read the{" "}
                  <a href="https://forum.il2sturmovik.com/topic/63326-installation-and-usage-of-srs-for-il-2-voice-comms-app/"
                    className="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                    guide on the forums
                  </a>
                  . Once you have installed IL2-SRS and have it running, your SRS radio client should automatically connect when you
                  join the Combat Box server. You’ll initially be placed in the neutral lobby, then on channel 1 for your coalition once
                  you spawn into your first plane.
                </p>

                {/* Embedded YouTube Video */}
                <div className="text-center mt-4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BglNuLR-ySI"
                    title="SRS video tutorial"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>


              <div className='row py-5'>
                <h4 className="text-uppercase">How to install IL-2 SRS</h4>
              </div>
              <div className="row py-5">
                <div className="col-md-4 order-md-1 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/extract.png`}
                    alt="Extract"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Once you extract the application, you should have a folder like this image.</div>
                </div>

                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/extract-click-autoupdate.png`}
                    alt="Click Autoupdate"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Click on the autoupdater.exe to download the latest version of SRS.</div>
                </div>

                <div className="col-md-4 order-md-3">

                  <p>
                    This guide should help you with the installation of IL-2 SRS.
                  </p>
                  <p>
                    First, you'll want to download the application from the link above. After downloading the application, you'll then need to run the app, to extract it to your computer. Once you've extracted SRS, your folder should look something like the images to the right. You'll then need to double click on the Autoupdater.exe.
                  </p>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/download.png`}
                    alt="Download"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>This image shows what the downloader looks like.</div>
                </div>

                <div className="col-md-8">
                  <p>
                    After clicking the Autoupdater.exe, you will download the latest version of SRS. When the download finishes, the Autoupdater will be completed.
                  </p>
                </div>
              </div>




              <div className="row py-5">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/locate-directory-button.png`}
                    alt="Locate IL2"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Click locate to search your computer for your IL-2 installation.</div>
                </div>
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/locate-directory-browse.png`}
                    alt="Browse to IL2 directory"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Locate your IL-2 installation directory.</div>
                </div>
                <div className="col-md-4 order-md-3">
                  <p>
                    You will then be prompted with the SRS installer dialog box. You will need to give SRS your IL-2 installation directory in order to complete the installation process. You can do this by clicking on the locate button, to locate the installation directory.
                  </p>
                </div>
              </div>


              <div className="row py-5">
                <div className="col-md-4 order-md-3 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/installation-complete.png`}
                    alt="Click OK"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Click OK to finish the installation.</div>
                </div>

                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/install-button.png`}
                    alt="Click Install"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Click install to install SRS.</div>
                </div>

                <div className="col-md-4 order-md-1">

                  <p>
                    Once you have located the IL-2 installation directory, you can click the install button. SRS will install on to your computer. Once it is done installing, you will be notified that it completed. Press the OK button to finish the install. Congrats! You can now use SRS while playing IL-2.
                  </p>
                </div>
              </div>


              <div className="row py-5">
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/click-srs-client.png`}
                    alt="Launch SRS"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>To use SRS, click the SRS-Client in your install directory.</div>
                </div>
                <div className="col-md-4 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/set-mic-headphones.png`}
                    alt="Mic and Headphone settings"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>Double check your microphone and headphone settings before launching IL-2.</div>
                </div>
                <div className="col-md-4 order-md-3">
                  <p>
                    To begin using SRS, click the SRS-Client.exe in your installation folder. This will start SRS for you. We highly recommend starting and connecting to SRS before launching IL-2. After you launch the client, make sure your mic and headphones are setup correctly in SRS. This is especially true if you are using a VR head mounted device, as often times SRS will switch back to your default microhpone and headphone settings detected by Windows. Once
                  </p>
                </div>
              </div>


              <div className="row border-bottom py-5">
                <div className="col-md-4 order-md-2 text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/srs-setup-guide/click-connect.png`}
                    alt="Click connect"
                    className="img-fluid"
                    style={{ height: "100%" }}
                  />
                  <div className='text-small fst-italic'>You are ready to connect to an SRS server. Click the connect button.</div>
                </div>

                <div className="col-md-8">
                  <p>
                    You are now ready to connect to an SRS server. Click the connect button. You will automatically be connected to the SRS server, when you join a multiplayer server on IL-2. As an example, when you join the Combat Box multiplayer server, you will automatically be connected to the Combat Box SRS server.
                  </p>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ServerSRS;
