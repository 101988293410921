import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Navbar() {
  const location = useLocation();
  const [rosterSubText, setRosterSubText] = useState('');
  const [serverSubText, setServerSubText] = useState('');

  useEffect(() => {
    if (location.pathname.includes('/pilots/active')) {
      setRosterSubText('Active Roster');
    } else if (location.pathname.includes('/pilots/memorial')) {
      setRosterSubText('Memorial Wall');
    } else{
      setRosterSubText('');
    }

    if (location.pathname.includes('/server-info/rules')) {
      setServerSubText('Server Rules')
    }
    else if (location.pathname.includes('/server-info/srs')) {
      setServerSubText('SRS Info')
    }
    else if (location.pathname.includes('/server-info/cb-radio')) {
      setServerSubText('CB Radio')
    }
    else if (location.pathname.includes('/server-info/support')) {
      setServerSubText('Support')
    }
    else {
      setServerSubText('');
    }
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img
            style={{ height: '40px' }}
            src={`${process.env.PUBLIC_URL}/images/combat_box_final_2.png`}
            alt="Combat Box Logo"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Main
              </NavLink>
            </li>

            {/* PILOTS */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="pilotRosterDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className='col-12 text-start'>
                <div>Pilots</div>
                {rosterSubText && <div className='text-small text-center'>{rosterSubText}</div>}
                </div>
              </a>
              <ul className="dropdown-menu" aria-labelledby="pilotRosterDropdown">
                <li>
                  <NavLink
                    to="/pilots/active"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    Active Roster
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/pilots/memorial"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    Memorial Wall
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* SERVER INFO */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="serverInfoDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className='col-12 text-start'>
                <div>Server Info</div>
                {serverSubText && <div className='text-small text-center'>{serverSubText}</div>}
                </div>
              </a>
              <ul className="dropdown-menu" aria-labelledby="serverInfoDropdown">
                <li>
                  <NavLink
                    to="/server-info/rules"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    Server Rules
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/server-info/srs"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    SRS Info
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/server-info/cb-radio"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    CB Radio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/server-info/support"
                    className={({ isActive }) => `dropdown-item ${isActive ? 'active' : ''}`}
                  >
                    Support
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* PLANE LIST */}
            {/* <li className="nav-item">
              <NavLink
                to="/plane-list"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Plane List
              </NavLink>
            </li> */}
            <li className="nav-item">
              <a
                href="https://discord.gg/PQN65PP92H"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.patreon.com/c/Combatbox"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Donate
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
