// src/components/PilotDetails/ReportTemplate.jsx
import React from "react";
import SpoilerText from "../SpoilerText";

export default function ReportTemplate({ sanitizedReport }) {
  const { title, titleClassification, body, footerResult, isClassified } = sanitizedReport || {};

  return (
    <div className="mt-3 mb-1 mx-2 border border-color-gray">
      <div className="d-flex flex-row justify-content-between px-1">
        <div className="text-start fw-bolder text-large">{title}</div>
        <div className="text-start fw-bold pt-1">{titleClassification}</div>
      </div>
      <div className="text-start border-top border-color-gray">
        <div className="p-2">
          {isClassified ? (
            <SpoilerText>
              {Array.isArray(body) ? (
                body.map((paragraph, index) => <p key={index}>{paragraph}</p>)
              ) : (
                <p>No data available</p>
              )}
            </SpoilerText>
          ) : Array.isArray(body) ? (
            body.map((paragraph, index) => <p key={index}>{paragraph}</p>)
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>
      <div className="text-end p-2">
        <strong>Mission Result:</strong> {footerResult}
      </div>
    </div>
  );
}
