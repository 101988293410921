
export default function getPhotoMapping() {
  const photoMapping = {
    "Abbeville Drucat Airfield": ["AbbevilleDrucatAirfield.jpg"],
    "Rouen Rail Yards": [
      "RouenRailYards-Zone1.jpg",
      "RouenRailYards-Zone2.jpg",
      "RouenRailYards-Zone3.jpg"
    ],
    "Carpiquet": ["CarpiquetAirfield.jpg"],
    "La Vielle": ["LaVielleAirfield.jpg"],
    "Pontorson": ["PontorsonAirfield.jpg"],
    "Triqueville": ["TrequevilleAirfield.jpg"],
    "Maupertus": ["MaupertusAirfield.jpg"],
    "Lonrai": ["LonraiAirfield.jpg"],
    "Cretteville": ["CrettevilleAirfield.jpg"],
    "Le Havre Octeville": ["LeHavreOctevilleAirfield.jpg"],
    "Beaumont Le Roger": ["Beaumont_leRogerAirfield.jpg"],
    "Saint-Valery-en-Caux Flak School": ["SaintValery-en-CauxFlak.jpg"],
    "Bolbec Aviation Fuel Logistics": ["BolbecAviationFuelLogistics.jpg"],
    "Arromanches Mulberry B": ["ArromanchesMulberryB.jpg"],
    "Cabourg Coastal Defences": ["CabourgCoastalDefences.jpg"],
    "Lisieux Ball Bearing Factory": ["LisieuxBallBearingFactory.jpg"],
    "Coutances Road and Rail Junction": ["CoutancesRoadAndRailJunction.jpg"],
    "Villedieu Fuel Depot": ["VilledieuFuelDepot.jpg"],
    "Vire Command And Logistics": ["VireCommandAndLogistics.jpg"],
    "Conde sur Noireau Tank Repair Station": ["CondeSurNoireauTankRe-Enforcements.jpg"],
    "Falaise Encampment": ["FalaiseTroopEncampment.jpg"],
    "Vimoutiers Sawmill and Logging Station": ["VimoutiersSawmill.jpg"],
    "L Aigle Supply Dump": ["LAigleSupplyDump.jpg"],
    "Argentan Switching Yard": ["Argentan.jpg"],
    "Saint Hilaire River Crossing": ["SaintHilaireRiverCrossing.jpg"],
    "La Ferte Mace Troop Encampment": ["LeFertaMaceTroopEncampment.jpg"],
    "Domfront HFDF Site": ["DomfrontHFDFSite.jpg"],
    "Perche HFDF Testing Facility": ["PerchHFDFTestingFacility.jpg"],
    "Dreux": ["DreaxAirfield.jpg"],
    "fw190a6": ["fw_190_a-6.png"],
    "fw190a8": ["fw_190_a-8.png"],
    "bf109g6late": ["bf_109_g-6 late.png"],
    "bf109g14": ["bf_109_g-14.png"],
    "bf110g2": ["bf_110_g-2.png"],
    "me410a1": ["me_410_a-1.png"],
    "bf109g6as": ["bf_109_g-6as.png"],
    "ju88a4": ["ju_88_a-4.png"],
    "ju88c6": ["ju_88_c-6.png"],
    "spitfiremkixc": ["spitfire_mk.ixc.png"],
    "typhoonmkib": ["typhoon_mk.ib.png"],
    "spitfiremkixe": ["spitfire_mk.ixe.png"],
    "p51b5": ["p-51b-5.png"],
    "a20b": ["a-20b.png"],
    "mosquitofbmkvis2": ["mosquito_f.b._mk.vi_ser.2.png"],
    "c47a": ["c-47a.png"],
    "p47d22": ["p-47d-22.png"],
    "p47d28": ["p-47d-28.png"],
    "p38j25": ["p-38j-25.png"],
    "p51d15": ["p-51d-15.png"],
    "ju523mg4e": ["ju_52_3mg4e.png"],
    "tempestmkvs2" : ["tempest_mk.v_ser.2.png"],
    "spitfiremkxiv" : ["spitfire_mk.xiv.png"],
    "spitfiremkxive" : ["spitfire_mk.xive.png"],
    "me262a" : ["me_262_a.png"],
    "ar234b2" : ["ar_234_b-2.png"],
    "bf109k4" : ["bf_109_k-4.png"],
    "ta152h1" : ["ta_152_h-1.png"],
    "fw190d9" : ["fw_190_d-9.png"],
    "Bf 109 G-2" : ["bf_109_g-2.png"],
    "Bf 109 G-4" : ["bf_109_g-4.png"],
    "Bf 109 F-4" : ["bf_109_f-4.png"],
    "A-20B" : ["a-20b.png"],
    "IL-2 AM-38F" : ["il-2_mod.1941.png"],
    "Pe-2 (series 87)" : ["pe-2_ser.87.png"],
    "pe2s87" : ["pe-2_ser.87.png"],
    "Hurricane Mk.II" : ["hurricane_mk.ii.png"],
    "P-39L-1" : ["p-39l-1.png"],
    "p39l1": ["p-39l-1.png"],
    "Fw 190 A-5" : ["fw_190_a-5.png"],
    "Bf 110 G-2" : ["bf_110_g-2.png"],
    "Ju 87 D-3" : ["ju_87_d-3.png"],
    "mig3s24" : ["mig-3_ser.24.png"],
    "yak7bs36" : ["yak-7b_ser.36.png"],
    "p40e1": ["p-40e-1.png"],
    "yak1s127" : ["yak-1b_ser.127.png"],
    "il2m43" : ["il-2_mod.1943.png"],
    "il2m42" : ["il-2_mod.1942.png"],
    "la5s8":["la-5_ser.8.png"],
    "lagg3s29" : ["lagg-3_ser.29.png"],
    "hurricanemkii" : ["hurricane_mk.ii.png"],
    "u2vs" : ["u-2vs.png"]


    
    // Add more airfields and their images as needed
  };


  return {
    getReconPhotos: (name) => (photoMapping[name]),
  };
}

