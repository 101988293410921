import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomModal from "./Modal"; // Import your custom modal
import axios from "axios";

function MapSlider({ maxDay, showSlider = true, showAnimationControls = true }) {
  const [sliderValue, setSliderValue] = useState(maxDay);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [intervalId, setIntervalId] = useState(null);

  const [error, setError] = useState(null);
  const [tourMode, setTourMode] = useState(null);
  const [loading, setLoading] = useState(true);

  const formattedValue = String(sliderValue).padStart(3, "0");

  useEffect(() => {
    const fetchTourMode = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://il2statsapi.combatbox.net/api/tour");
        if (response.data?.current_tour?.tour_mode) {
          setTourMode(response.data.current_tour.tour_name);
        } else {
          throw new Error("Tour mode not found in API response");
        }
      } catch (err) {
        console.error("Error fetching tour mode:", err);
        setError("Unable to determine tour mode.");
      } finally {
        setLoading(false);
      }
    };

    fetchTourMode();
  }, []);

  const sanitizedTourMode = () => {
    if (!tourMode) return "";
    // Split the string by spaces and get the last word
    const parts = tourMode.trim().split(" ");
    return parts[parts.length - 1];
  };

  const sanitizedMode = sanitizedTourMode();

  // (A) Use -small.jpg in the main display
  const smallImageUrl = `https://m2.combatbox.net/${sanitizedMode}-campaign/${sanitizedMode}-campaign-day-${formattedValue}-small.jpg`;

  // (B) For the modal, the full-size version => no suffix
  const bigImageUrl = `https://m2.combatbox.net/${sanitizedMode}-campaign/${sanitizedMode}-campaign-day-${formattedValue}.jpg`;

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);

  const toggleAnimation = () => {
    if (isAnimating) {
      clearInterval(intervalId);
      setIsAnimating(false);
    } else {
      const id = setInterval(() => {
        setSliderValue((prev) => (prev < maxDay ? prev + 1 : 1));
      }, 100);
      setIntervalId(id);
      setIsAnimating(true);
    }
  };

  const handleSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value, 10));
  };

  const openImageModal = () => {
    // (C) Use the big image in the modal
    setModalImageUrl(bigImageUrl);
    setShowModal(true);
  };

  return (
    <div className="px-2 pb-2 justify-content-center border">
      {/* Image with top overlay */}
      <div className="position-relative text-center">
        {/* (A) Show the smaller image in the main UI */}
        <img
          id="campaignImage"
          src={smallImageUrl}
          alt={`Campaign Day ${sliderValue}`}
          className="img-fluid mt-3"
          onClick={openImageModal}
          style={{ cursor: "pointer" }}
        />
        <div
          className="position-absolute top-0 start-50 translate-middle-x text-white text-center map-date"
        >
          {sliderValue === maxDay
            ? `Current Day - ${sliderValue}`
            : `Day: ${sliderValue}`}
        </div>
      </div>

      {/* Controls at the bottom */}
      <div className="col-12 mt-3">
        {showSlider && (
          <input
            type="range"
            min="1"
            max={maxDay}
            value={sliderValue}
            onChange={handleSliderChange}
            className="form-range"
            id="myRange"
          />
        )}

        {showAnimationControls && (
          <div className="text-center">
            <button onClick={toggleAnimation} className="btn btn-dark">
              {isAnimating ? "Stop Animation" : "Animate Days"}
            </button>
          </div>
        )}
      </div>

      {/* Modal with big image */}
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={`Day ${sliderValue}`}
        body={<img src={modalImageUrl} alt={`Day ${sliderValue}`} className="img-fluid" />}
        size="xxl"
      />
    </div>
  );
}

MapSlider.propTypes = {
  maxDay: PropTypes.number.isRequired,
  showSlider: PropTypes.bool, // Control visibility of the slider
  showAnimationControls: PropTypes.bool, // Control visibility of animation controls
};

export default MapSlider;
